import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "axios";
import { sessionExpired } from "./SessionSlice";
import { FrontendUser, WaitlistRun } from "../types";
import { api } from "../utils/utils";

export interface WaitlistRunsSlice {
  waitlistRunIds: string[];
  waitlistRuns: { [waitlistRunId: string]: WaitlistRun };
  waitlistRunsLoading: { [waitlistRunId: string]: boolean };
  fetchingRunsLoading: boolean;
  agentId: string | null;
  inProgress: number;
  loadingCreatingRun: boolean;
}

const initialState: WaitlistRunsSlice = {
  waitlistRunIds: [],
  waitlistRuns: {},
  waitlistRunsLoading: {},
  fetchingRunsLoading: false,
  agentId: null,
  inProgress: 0,
  loadingCreatingRun: false,
};

export const fetchWaitlistRuns = createAsyncThunk<
  { waitlistRuns: WaitlistRun[] },
  { token: string; agentId: string; quietly?: boolean },
  { rejectValue: Error }
>("waitlist/fetchWaitlistRuns", async ({ token, agentId }, { rejectWithValue }) => {
  try {
    const response = await api.get(`/waitlist/${agentId}/runs`, token);

    if (response.data) {
      return { waitlistRuns: response.data };
    }
    throw new Error("Invalid response data");
  } catch (error: any) {
    if (error.response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch waitlist runs."));
  }
});

export const fetchWaitlistRun = createAsyncThunk<{ waitlistRun: WaitlistRun }, { token: string; waitlistRunId: string }, { rejectValue: Error }>(
  "waitlist/fetchWaitlistRun",
  async ({ token, waitlistRunId }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/waitlist/runs/${waitlistRunId}`, token);

      if (response.data) {
        return { waitlistRun: response.data as WaitlistRun };
      }
      throw new Error("Invalid response data");
    } catch (error: any) {
      if (error.response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch waitlist run."));
    }
  }
);

export const executeRun = createAsyncThunk<void, { token: string; runId: string }, { rejectValue: Error }>(
  "waitlist/executeRun",
  async ({ token, runId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/waitlist/runs/${runId}/start`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to execute run");
      }

      await dispatch(fetchWaitlistRuns({ token, agentId: state.waitlistRuns.agentId! }));
    } catch (error: any) {
      if (error.response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to execute run."));
    }
  }
);

export const deleteRun = createAsyncThunk<void, { token: string; runId: string }, { rejectValue: Error }>(
  "waitlist/deleteRun",
  async ({ token, runId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/waitlist/runs/${runId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status !== 200) {
        throw new Error("Failed to delete run");
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to delete run."));
    }
  }
);

export const createRun = createAsyncThunk<void, { token: string; params: any }, { rejectValue: Error }>(
  "waitlist/createRun",
  async ({ token, params }, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const agentId = state.waitlistRuns.agentId;

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/waitlist/${agentId}/runs`, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        throw new Error("Failed to create run");
      }

      await dispatch(fetchWaitlistRuns({ token, agentId: agentId! }));
    } catch (error: any) {
      if (error.response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error(error));
    }
  }
);

const waitlistRunsSlice = createSlice({
  name: "waitlistRuns",
  initialState,
  reducers: {
    setWaitlistRun: (state, action: PayloadAction<{ waitlistRunId: string; waitlistRun: WaitlistRun | undefined }>) => {
      if (action.payload.waitlistRun) {
        state.waitlistRuns[action.payload.waitlistRunId] = action.payload.waitlistRun;
        if (!state.waitlistRunIds.includes(action.payload.waitlistRunId)) {
          state.waitlistRunIds.unshift(action.payload.waitlistRunId);
        } else {
          state.waitlistRunIds = state.waitlistRunIds.filter((id) => id !== action.payload.waitlistRunId);
          state.waitlistRunIds.unshift(action.payload.waitlistRun.waitlistRunId);
        }
      } else {
        delete state.waitlistRuns[action.payload.waitlistRunId];
        state.waitlistRunIds = state.waitlistRunIds.filter((id) => id !== action.payload.waitlistRunId);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWaitlistRuns.pending, (state, action) => {
        if (action.meta.arg.agentId !== state.agentId) {
          state.waitlistRunIds = [];
        }
        // Only set fetchingRunsLoading to true if quietly is false or undefined
        state.inProgress += 1;
      })
      .addCase(fetchWaitlistRuns.fulfilled, (state, action) => {
        action.payload.waitlistRuns.forEach((run) => {
          state.waitlistRuns[run.waitlistRunId] = run;
          if (!state.waitlistRunIds.includes(run.waitlistRunId)) {
            state.waitlistRunIds.push(run.waitlistRunId);
          }
        });
        state.agentId = action.meta.arg.agentId;
        state.inProgress -= 1;
        if (state.inProgress === 0) {
          state.fetchingRunsLoading = false;
        }
      })
      .addCase(fetchWaitlistRuns.rejected, (state, action) => {
        state.inProgress -= 1;
        if (state.inProgress === 0) {
          state.fetchingRunsLoading = false;
        }
      })
      .addCase(fetchWaitlistRun.pending, (state, action) => {
        state.waitlistRunsLoading[action.meta.arg.waitlistRunId] = true;
      })
      .addCase(fetchWaitlistRun.fulfilled, (state, action) => {
        state.waitlistRuns[action.payload.waitlistRun.waitlistRunId] = action.payload.waitlistRun;
        if (!state.waitlistRunIds.includes(action.payload.waitlistRun.waitlistRunId)) {
          state.waitlistRunIds.unshift(action.payload.waitlistRun.waitlistRunId);
        } else {
          state.waitlistRunIds = state.waitlistRunIds.filter((id) => id !== action.payload.waitlistRun.waitlistRunId);
          state.waitlistRunIds.unshift(action.payload.waitlistRun.waitlistRunId);
        }
        state.waitlistRunsLoading[action.payload.waitlistRun.waitlistRunId] = false;
      })
      .addCase(fetchWaitlistRun.rejected, (state, action) => {
        state.waitlistRunsLoading[action.meta.arg.waitlistRunId] = false;
      })
      .addCase(executeRun.pending, (state) => {
        state.fetchingRunsLoading = true;
        state.inProgress += 1;
      })
      .addCase(executeRun.fulfilled, (state) => {
        state.inProgress -= 1;
        if (state.inProgress === 0) {
          state.fetchingRunsLoading = false;
        }
      })
      .addCase(executeRun.rejected, (state, action) => {
        state.inProgress -= 1;
        if (state.inProgress === 0) {
          state.fetchingRunsLoading = false;
        }
      })
      .addCase(deleteRun.pending, (state) => {
        state.fetchingRunsLoading = true;
        state.inProgress += 1;
      })
      .addCase(deleteRun.fulfilled, (state, action) => {
        const runId = action.meta.arg.runId; // Access the runId from the thunk args
        delete state.waitlistRuns[runId];
        state.waitlistRunIds = state.waitlistRunIds.filter((id) => id !== runId);
        state.inProgress -= 1;
        if (state.inProgress === 0) {
          state.fetchingRunsLoading = false;
        }
      })
      .addCase(deleteRun.rejected, (state, action) => {
        state.inProgress -= 1;
        if (state.inProgress === 0) {
          state.fetchingRunsLoading = false;
        }
      })
      .addCase(createRun.pending, (state) => {
        state.fetchingRunsLoading = true;
        state.inProgress += 1;
        state.loadingCreatingRun = true;
      })
      .addCase(createRun.fulfilled, (state) => {
        state.inProgress -= 1;
        if (state.inProgress === 0) {
          state.fetchingRunsLoading = false;
        }
        state.loadingCreatingRun = false;
      })
      .addCase(createRun.rejected, (state, action) => {
        state.inProgress -= 1;
        if (state.inProgress === 0) {
          state.fetchingRunsLoading = false;
        }
        state.loadingCreatingRun = false;
      });
  },
});

export const { setWaitlistRun } = waitlistRunsSlice.actions;

export const selectWaitlistRuns = (state: RootState) => state.waitlistRuns.waitlistRuns;

export default waitlistRunsSlice.reducer;
