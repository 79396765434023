import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Typography, Button, FormControl, Autocomplete, TextField, Checkbox, ListItemText, Paper, Popper } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import SignoutHeader from "../subcomponents/SignoutHeader";
import DeleteAccountModal from "../confirmationModals/deleteAccountModal";
import { PrimaryButton } from "../subcomponents/CustomButton";
import { Colors } from "../Colors";
import { FrontendUser, Organization, Agent, EHR } from "../types";
import axios from "axios";
import { useSnackbar } from "../providers/SnackbarProvider";
import UploadLogoModal from "../forms/UploadLogo";
import { QRCodeCanvas } from "qrcode.react";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import CustomAutocomplete from "../components/CustomAutocomplete";
import { getSession, updateOrganizationAttribute } from "../slices/AuthSlice";

const Account: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { showMessage } = useSnackbar();

  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);
  const [uploadLogoModalOpen, setUploadLogoModalOpen] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [selectedProviders, setSelectedProviders] = React.useState<string[]>(organization?.healthieProviderIds || []);
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = React.useState<string[]>(organization?.healthieAppointmentTypeIds || []);
  const [selectedContactTypes, setSelectedContactTypes] = React.useState<string[]>(organization?.healthieContactTypes || []);

  const loadingProviders: boolean = useSelector((state: RootState) => state.healthieIntegration.loadingProviders);
  const loadingAppointments: boolean = useSelector((state: RootState) => state.healthieIntegration.loadingAppointments);

  const providers = useSelector((state: RootState) => state.healthieIntegration.providers);
  const appointments = useSelector((state: RootState) => state.healthieIntegration.appointments);
  const contactTypes = useSelector((state: RootState) => state.healthieIntegration.contactTypes);
  const [selfAddUrl, setSelfAddUrl] = useState(`https://app.penciled.com/patient/self-add/${organization?.organizationId}`);
  const [loadingUploadingLogo, setLoadingUploadingLogo] = useState(false);

  useEffect(() => {
    if (organization && organization.organizationId) {
      setSelfAddUrl(`https://app.penciled.com/patient/self-add/${organization?.organizationId}`);
    }
  }, [organization?.organizationId]);

  useEffect(() => {
    setSelectedProviders(organization?.healthieProviderIds || []);
  }, [organization?.healthieProviderIds]);

  useEffect(() => {
    setSelectedAppointmentTypes(organization?.healthieAppointmentTypeIds || []);
  }, [organization?.healthieAppointmentTypeIds]);

  useEffect(() => {
    setSelectedContactTypes(organization?.healthieContactTypes || []);
  }, [organization?.healthieContactTypes]);

  const providerOptions = useMemo(
    () =>
      providers
        .map((provider) => ({ value: provider.id, label: provider.firstName + " " + provider.lastName }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [providers]
  );

  const appointmentOptions = useMemo(
    () => appointments.map((appointment) => ({ value: appointment.id, label: appointment.name })).sort((a, b) => a.label.localeCompare(b.label)),
    [appointments]
  );

  const contactTypeOptions = useMemo(
    () => contactTypes.map((contactType) => ({ value: contactType, label: contactType })).sort((a, b) => a.label.localeCompare(b.label)),
    [contactTypes]
  );

  const dispatch = useAppDispatch();

  const handleDeleteClick = () => {
    setOpenModal(true);
  };

  const handleFileChange = (file: File) => {
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setSelectedFile(file);
      setUploadLogoModalOpen(false);
      handleUpload();
    } else {
      showMessage("Please select a JPG or PNG file", "error");
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setLoadingUploadingLogo(true);
      const formData = new FormData();
      formData.append("logo", selectedFile);

      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/organizations/upload-logo`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user?.token}`,
          },
        });
        showMessage("Logo uploaded successfully", "success");

        await dispatch(getSession());
      } catch (error) {
        console.error("Error uploading logo:", error);
        showMessage("Failed to upload logo", "error");
      } finally {
        setLoadingUploadingLogo(false);
      }
    } else {
      showMessage("No file selected", "error");
      setLoadingUploadingLogo(false);
    }
  };

  const handleDownloadQRCode = () => {
    if (canvasRef.current) {
      const url = canvasRef.current.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = url;
      link.download = `QRCode-${organization?.organizationId}.png`;
      link.click();
    }
  };

  const handleUpdateProviders = async () => {
    if (!user?.token) {
      return;
    }

    try {
      await dispatch(updateOrganizationAttribute({ token: user.token, attribute: "healthieProviderIds", value: selectedProviders }));
      showMessage("Providers updated successfully", "success");
    } catch (error) {
      console.error("Error updating providers:", error);
      showMessage("Failed to update providers", "error");
    }
  };

  const handleUpdateAppointmentTypes = async () => {
    if (!user?.token) {
      return;
    }

    try {
      await dispatch(updateOrganizationAttribute({ token: user.token, attribute: "healthieAppointmentTypeIds", value: selectedAppointmentTypes }));
      showMessage("Appointment types updated successfully", "success");
    } catch (error) {
      console.error("Error updating appointment types:", error);
      showMessage("Failed to update appointment types", "error");
    }
  };

  const handleUpdateContactTypes = async () => {
    if (!user?.token) {
      return;
    }

    try {
      await dispatch(updateOrganizationAttribute({ token: user.token, attribute: "healthieContactTypes", value: selectedContactTypes }));
      showMessage("Contact types updated successfully", "success");
    } catch (error) {
      console.error("Error updating contact types:", error);
      showMessage("Failed to update contact types", "error");
    }
  };

  return (
    <Box sx={{ color: "#FFF" }}>
      <SignoutHeader />
      <Typography variant="h5" sx={{ fontSize: { xs: "1.3rem", sm: "1.8rem" }, margin: { xs: "80px 0 10px 0", lg: "20px 0" } }}>
        My Account Info
      </Typography>

      {organization?.logoUrl && (
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "20px" }}>
          <img src={organization.logoUrl} alt="Organization Logo" style={{ maxWidth: "100px", height: "auto" }} />
        </Box>
      )}

      <UploadLogoModal open={uploadLogoModalOpen} onClose={() => setUploadLogoModalOpen(false)} onUpload={handleFileChange} />
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Typography variant="h6" sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>
          Name:{" "}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>
          {user?.firstName} {user?.lastName}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Typography variant="h6" sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>
          Email:{" "}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>
          {" "}
          {user?.email}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Typography variant="h6" sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>
          Organization:{" "}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>
          {organization?.name || "Organization Name Not Added"}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Typography variant="h6" sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>
          Callback Number:{" "}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>
          {organization?.phoneNumber || "Callback Number Not Added"}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Typography variant="h6" sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>
          Timezone:{" "}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>
          {organization?.timezone || "Timezone Not Found"}
        </Typography>
      </Box>

      {/* File Upload Section */}
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "20px", marginBottom: "30px" }}>
        {/* Button to trigger file input */}
        <Button onClick={() => setUploadLogoModalOpen(true)}>Upload Logo</Button>

        {selectedFile && <Typography variant="subtitle1">{selectedFile.name}</Typography>}
      </Box>

      {loadingUploadingLogo && <LoadingWithMessage message="Uploading logo..." size={10} />}

      {/* QR Code Section */}
      <Box sx={{ marginTop: "20px" }}>
        <Typography variant="h6" sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, marginBottom: "10px" }}>
          Download QR Code for Self-Add
        </Typography>
        <Typography variant="subtitle1" sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, marginBottom: "10px" }}>
          Link to self-add:{" "}
          <a href={selfAddUrl} target="_blank" rel="noopener noreferrer" style={{ color: "#4EC5FF" }}>
            {selfAddUrl}
          </a>
        </Typography>
        {organization && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column", // Stack elements vertically
                alignItems: "left", // Center-align the elements
                marginTop: "20px",
              }}
            >
              <QRCodeCanvas
                value={selfAddUrl}
                size={128} // Adjust the size as needed
                fgColor="#000000"
                bgColor="#ffffff"
                level="L"
                includeMargin={false}
                ref={canvasRef} // Reference to the canvas element
              />
              <Button
                variant="contained"
                sx={{
                  marginTop: "10px",
                  backgroundColor: Colors.primary,
                  "&:hover": { backgroundColor: Colors.primaryDark },
                  maxWidth: "150px",
                  maxHeight: "50px",
                }}
                onClick={handleDownloadQRCode}
              >
                Download QR Code
              </Button>
            </Box>
          </>
        )}
      </Box>

      <PrimaryButton
        onClick={handleDeleteClick}
        sx={{
          padding: "16px 32px", // Adjust padding as needed
          marginTop: "20px",
          backgroundColor: Colors.error,
          "&:hover": {
            backgroundColor: Colors.errorDark,
          },
        }}
      >
        Delete Account
      </PrimaryButton>

      <DeleteAccountModal open={openModal} onClose={() => setOpenModal(false)} />

      {organization?.integrations.includes(EHR.healthie) && !loadingProviders && (
        <CustomAutocomplete options={providerOptions} selectedItems={selectedProviders} setSelectedItems={setSelectedProviders} label={"Providers"} />
      )}
      {organization?.integrations.includes(EHR.healthie) && loadingProviders && <LoadingWithMessage message="Loading providers..." size={10} />}
      {organization?.integrations.includes(EHR.healthie) && !loadingProviders && selectedProviders.length > 0 && (
        <Box>
          <Typography variant="body1" mt={2}>
            Selected Providers
          </Typography>
          <Box
            sx={{
              maxHeight: "400px", // Set your desired max height
              overflowY: "auto", // Enable vertical scrolling
              border: "1px solid #ddd", // Optional: add border for visual distinction
              padding: "8px", // Optional: add padding
            }}
          >
            {selectedProviders.map((providerId) => {
              const provider = providers.find((provider) => providerId === provider.id);
              return (
                provider && (
                  <Box key={provider.id}>
                    {provider.firstName} {provider.lastName}
                  </Box>
                )
              );
            })}
          </Box>
        </Box>
      )}

      {organization?.integrations.includes(EHR.healthie) && !loadingProviders && (
        <PrimaryButton
          onClick={handleUpdateProviders}
          sx={{
            padding: "16px 32px", // Adjust padding as needed
            marginTop: "20px",
            backgroundColor: Colors.primary,
            "&:hover": {
              backgroundColor: Colors.primaryDark,
            },
          }}
        >
          Update Providers
        </PrimaryButton>
      )}

      {organization?.integrations.includes(EHR.healthie) && !loadingAppointments && (
        <CustomAutocomplete
          options={appointmentOptions}
          selectedItems={selectedAppointmentTypes}
          setSelectedItems={setSelectedAppointmentTypes}
          label={"Appointment Types"}
        />
      )}
      {organization?.integrations.includes(EHR.healthie) && loadingAppointments && <LoadingWithMessage message="Loading appointments..." size={10} />}

      {organization?.integrations.includes(EHR.healthie) && !loadingAppointments && selectedAppointmentTypes.length > 0 && (
        <Box>
          <Typography variant="body1" mt={2}>
            Selected Appointment Types
          </Typography>
          <Box
            sx={{
              maxHeight: "400px", // Set your desired max height
              overflowY: "auto", // Enable vertical scrolling
              border: "1px solid #ddd", // Optional: add border for visual distinction
              padding: "8px", // Optional: add padding
            }}
          >
            {selectedAppointmentTypes.map((appointmentId) => {
              const appointment = appointments.find((appointment) => appointmentId === appointment.id);
              return appointment && <Box key={appointment.id}>{appointment.name}</Box>;
            })}
          </Box>
        </Box>
      )}

      {organization?.integrations.includes(EHR.healthie) && !loadingAppointments && (
        <PrimaryButton
          onClick={handleUpdateAppointmentTypes}
          sx={{
            padding: "16px 32px", // Adjust padding as needed
            marginTop: "20px",
            backgroundColor: Colors.primary,
            "&:hover": {
              backgroundColor: Colors.primaryDark,
            },
          }}
        >
          Update Appointment Types
        </PrimaryButton>
      )}

      {organization?.integrations.includes(EHR.healthie) && (
        <CustomAutocomplete
          options={contactTypeOptions}
          selectedItems={selectedContactTypes}
          setSelectedItems={setSelectedContactTypes}
          label={"Contact Types"}
        />
      )}

      {organization?.integrations.includes(EHR.healthie) && (
        <PrimaryButton
          onClick={handleUpdateContactTypes}
          sx={{
            padding: "16px 32px", // Adjust padding as needed
            marginTop: "20px",
            backgroundColor: Colors.primary,
            "&:hover": {
              backgroundColor: Colors.primaryDark,
            },
          }}
        >
          Update Contact Types
        </PrimaryButton>
      )}
    </Box>
  );
};

export default Account;
