import React from "react";
import { DateTime } from "luxon";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { Box } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import { CallStatuses, CloudFaxProvider, EHR, OutcomeSentiment, WaitlistTextStates } from "../types";
import calendlyLogo from "../assets/Calendly.png";
import faxplusLogo from "../assets/faxpluslogo.png";
import healthieLogo from "../assets/HealthieWhiteLogo.png";
import { Colors } from "../Colors";
import { snakeCase } from "lodash";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import QuestionMarkIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import axios, { AxiosResponse } from "axios";

/**
 * Takes in a timezone-aware ISO 8601 date string `isoString` and returns a formatted date string in the format 'EEEE, M/d/yy at h:mm a' in the specified or browser's local timezone.
 *
 * If the input is invalid, throw an error.
 *
 * @param isoString - The timezone-aware ISO 8601 date string (e.g., 2024-05-20T11:30:12.000-07:00)
 * @param timezone - The timezone to set the date to (optional)
 * @returns The formatted date string in the format 'EEEE, M/d/yy at h:mm a' in the specified or browser's local timezone
 */
export function formatIsoToCustomDateStringWithEEEEHHMMA(isoString: string, timezone?: string): string {
  try {
    const targetTimeZone: string = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Parse the ISO string and set it to the determined timezone
    const dateTime = DateTime.fromISO(isoString, { setZone: true }).setZone(targetTimeZone);

    if (!dateTime.isValid) {
      throw new Error("Invalid ISO 8601 date string");
    }

    return dateTime.toFormat("EEEE, M/d/yy 'at' h:mm a \(ZZ\)");
  } catch (error) {
    console.log("Invalid date", error);
    return "Invalid date";
  }
}

/**
 * Takes in a timezone-aware ISO 8601 date string `isoString` and returns a formatted date string in the format 'Monday, M/d/yy'.
 *
 * If the input is invalid, throw an error.
 *
 * @param isoString - The timezone-aware ISO 8601 date string eg 2024-05-20T11:30:12.000-07:00
 * @returns The formatted date string in the format 'Monday, M/d/yy'
 * @throws An error if the input ISO string is invalid
 */
export function formatIsoToCustomDateStringWithEEEE(isoString: string): string {
  try {
    // Parse the ISO string with timezone
    const dateTime = DateTime.fromISO(isoString, { setZone: true });

    // Check if the DateTime object is valid
    if (!dateTime.isValid) {
      // throw new Error('Invalid ISO 8601 date string');
      return isoString;
    }

    // Extract the timezone from the input ISO string
    const timezone = dateTime.zoneName;

    // Set the DateTime object to the extracted timezone
    const dateTimeWithZone = dateTime.setZone(timezone);

    // Return the formatted date string
    return dateTimeWithZone.toFormat("EEEE, M/d/yy");
  } catch (error) {
    console.error("formatIsoToCustomDateStringWithEEEE Error: ", error);
    throw error; // Rethrow the error to ensure it propagates correctly
  }
}

export const textStateIcons: Record<WaitlistTextStates, React.ReactNode> = {
  [WaitlistTextStates.firstMessageSent]: <Box width={24} height={24} />,
  [WaitlistTextStates.inProgress]: <LoopIcon />,
  [WaitlistTextStates.accepted]: <EventAvailableIcon />,
  [WaitlistTextStates.declined]: <EventBusyIcon />,
  [WaitlistTextStates.notContacted]: <DoNotDisturbIcon />,
  [WaitlistTextStates.expired]: <Box width={24} height={24} />,
};

// Define the colors for each state
export const textStateColors: Record<WaitlistTextStates, string> = {
  [WaitlistTextStates.firstMessageSent]: "#4CAF50",
  [WaitlistTextStates.inProgress]: "#757575",
  [WaitlistTextStates.accepted]: "#4CAF50",
  [WaitlistTextStates.declined]: "#F44336",
  [WaitlistTextStates.notContacted]: "#757575",
  [WaitlistTextStates.expired]: "#757575",
};

/**
 * Generates a CryptoKey object from a PEM-formatted public key.
 *
 * @param publicKeyPem - The PEM-formatted public key
 * @returns - The CryptoKey object
 */
export async function importPublicKey(publicKeyPem: string): Promise<CryptoKey> {
  if (!publicKeyPem) {
    throw new Error("Public key not found");
  }

  // Correctly replace PEM header, footer, and newlines
  const base64String = publicKeyPem
    .replace(/-----BEGIN PUBLIC KEY-----/g, "")
    .replace(/-----END PUBLIC KEY-----/g, "")
    .replace(/\s+/g, ""); // Removes all whitespace including newlines

  if (!base64String) {
    throw new Error("Base64 string not found after replacements");
  }

  // Decode the base64 string to binary data
  const binaryDerString = window.atob(base64String);

  const binaryDer = new Uint8Array(binaryDerString.length);
  for (let i = 0; i < binaryDerString.length; i++) {
    binaryDer[i] = binaryDerString.charCodeAt(i);
  }

  return await window.crypto.subtle.importKey("spki", binaryDer.buffer, { name: "RSA-OAEP", hash: "SHA-256" }, true, ["encrypt"]);
}

/**
 * Given a public key `publicKey` and data `data`, encrypts the data using the public key.
 *
 * @param publicKey - The public key to use for encryption
 * @param data - The data to encrypt
 * @returns - The encrypted data
 */
export async function encryptData(publicKey: CryptoKey, data: string): Promise<string> {
  const encoded = new TextEncoder().encode(data);
  const encrypted = await window.crypto.subtle.encrypt({ name: "RSA-OAEP" }, publicKey, encoded);
  return window.btoa(String.fromCharCode(...new Uint8Array(encrypted)));
}

/**
 * Encrypts the given data `dataToEncrypt` using the public key stored in the environment variable `REACT_APP_PUBLIC_ENCRYPTION_KEY`.
 *
 * @param dataToEncrypt - The data to encrypt
 */
export async function encryptTokenWithPublicKey(dataToEncrypt: string): Promise<string> {
  const publicKeyPem = process.env.REACT_APP_PUBLIC_ENCRYPTION_KEY?.replace(/\\n/g, "\n");

  try {
    if (!publicKeyPem) {
      console.error("Public key not found");
      throw new Error("Public key not found");
    }

    const publicKey: CryptoKey = await importPublicKey(publicKeyPem);
    return await encryptData(publicKey, dataToEncrypt);
  } catch (error) {
    console.error("Encryption error:", error);
    throw error;
  }
}

export const cloudFaxProviderNames = {
  [CloudFaxProvider.faxPlus]: "Fax.Plus",
  [CloudFaxProvider.other]: "Other",
};

export const EHRLogos = {
  [EHR.healthie]: healthieLogo,
};

export const cloudFaxProviderLogos = {
  [CloudFaxProvider.faxPlus]: faxplusLogo,
  [CloudFaxProvider.other]: faxplusLogo,
};

export const callStatusDisplay = (status: CallStatuses) => {
  switch (status) {
    case CallStatuses.calling:
      return "Calling";
    case CallStatuses.complete:
      return "Complete";
    case CallStatuses.error:
      return "Error";
    case CallStatuses.voicemail:
      return "Voicemail";
  }
};

export const callStatusColor = (status: CallStatuses) => {
  switch (status) {
    case CallStatuses.calling:
      return Colors.primary;
    case CallStatuses.complete:
      return Colors.success;
    case CallStatuses.error:
      return Colors.error;
    case CallStatuses.voicemail:
      return "white";
  }
};

export const getOutcomeColor = (outcomeSentiment: OutcomeSentiment) => {
  if (!outcomeSentiment) return Colors.grey3;
  switch (outcomeSentiment) {
    case OutcomeSentiment.neutral:
      return "white";
    case OutcomeSentiment.negative:
      return Colors.error;
    case OutcomeSentiment.positive:
      return Colors.success;
    default:
      return Colors.grey3;
  }
};

/**
 * Converts a snake_case string to an official format.
 *
 * @param input - The snake_case string to convert.
 * @returns The string in official format.
 */
export function convertSnakeCaseToOfficial(input: string): string {
  return input
    .split("_") // Split the string by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(" "); // Join the words with a space
}

/**
 * Converts a camelCase string to an official format.
 *
 * @param input - The camelCase string to convert.
 * @returns The string in official format.
 */
export function convertCamelCaseToOfficial(input: string): string {
  return input
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert a space before uppercase letters that are preceded by lowercase letters
    .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter of the string
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
}

/**
 * Converts a kebab-case string to an official format.
 *
 * @param input - The kebab-case string to convert.
 * @returns The string in official format.
 */
export function convertKebabCaseToOfficial(input: string): string {
  return input
    .split("-") // Split the string by hyphens
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(" "); // Join the words with a space
}

/**
 * Validates if a string is a valid phone number in E.164 format with or without country code.
 *
 * @param string - The string to check
 * @returns A boolean indicating whether the string is a valid E.164 phone number
 */
export function isPhoneNumber(string: string): boolean {
  // Regular expression to match phone numbers with:
  // - +1xxxxxxxxxx
  // - 1xxxxxxxxxx
  // - xxxxxxxxxx
  return /^(\+1|1)?\d{10}$/.test(string);
}

/**
 * Converts a phone number into E.164 format, which consists of a + followed by the country code and subscriber number.
 *
 *
 * Accepts any format of phone number listed:
 * `+1xxxxxxxxxx`, (xxx) xxx-xxxx, xxx-xxx-xxxx, xxxxxxxxxx, and returns `+1xxxxxxxxxx`.
 *
 * https://www.twilio.com/docs/glossary/what-e164
 *
 * @param phoneNumber - The phone number to convert
 * @param countryCode - The country code to prepend to the phone number
 * @returns The phone number in the format '+[countryCode][phoneNumber]'
 */
export function convertToCallablePhoneNumber(phoneNumber: string, countryCode: string = "1"): string {
  if (!phoneNumber) {
    throw new Error("Invalid phone number");
  }

  // Remove all non-numeric characters except the leading plus sign if present
  const cleanedPhoneNumber = phoneNumber.replace(/(?!^\+)\D/g, "");

  // Check if the phone number is already in the format `+1xxxxxxxxxx`
  if (cleanedPhoneNumber.startsWith(`+${countryCode}`) && cleanedPhoneNumber.length === countryCode.length + 11) {
    return cleanedPhoneNumber;
  }

  // Ensure it returns a phone number in the format `+1xxxxxxxxxx`
  return `+${countryCode}${cleanedPhoneNumber.replace(/^\+/, "")}`;
}

/**
 * Converts an object `obj` to snake case
 *
 * @param obj - The object to convert
 * @returns The object in snake case
 */
export function toSnakeCase(obj: any): any {
  if (!obj) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((v) => toSnakeCase(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      result[snakeCase(key)] = toSnakeCase(obj[key]); // Use the imported snakeCase function
      return result;
    }, {} as any);
  }
  return obj;
}

/**
 * Given a timezone-aware ISO 8601 date string `isoString`, returns a formatted time string in the format 'EEEE, MMMM d ordinal at h:mm a'.
 *
 * @param dateTimeWithZone - The timezone-aware DateTime object eg 2024-05-20T11:30:12.000-07:00'
 * @param justDay - True if only the day should be returned. Default false (time is included)
 * @returns The formatted time string in the format 'EEEE, MMMM d ordinal at h:mm a'
 */
export function formatReadableTime(dateTimeWithZone: string, justDay: boolean = false): string {
  // Helper function to get the ordinal suffix
  function getOrdinal(n: number): string {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return (v - 20) % 10 > 0 ? s[v % 10] || s[0] : s[v] || s[0];
  }

  // Parse the ISO string with timezone
  const dateTime = DateTime.fromISO(dateTimeWithZone, { setZone: true });

  // Format the date parts
  const dayOfWeek = dateTime.toFormat("EEEE");
  const month = dateTime.toFormat("MMMM");
  const day = dateTime.day;
  const hour = dateTime.toFormat("h");
  const minutes = dateTime.minute === 0 ? "" : `:${dateTime.toFormat("mm")}`;
  const amPm = dateTime.toFormat("a").toUpperCase();

  // Return the formatted string
  return justDay ? `${dayOfWeek}, ${month} ${day}${getOrdinal(day)}` : `${dayOfWeek}, ${month} ${day}${getOrdinal(day)} at ${hour}${minutes} ${amPm}`;
}

/**
 * Renders an icon based on the outcome sentiment.
 *
 * @param outcomeSentiment - The outcome sentiment
 * @returns The icon component
 */
export const renderIconBasedOnOutcomeSentiment = (outcomeSentiment: OutcomeSentiment) => {
  if (!outcomeSentiment) return <QuestionMarkIcon style={{ color: Colors.coolWhite }} />;
  switch (outcomeSentiment) {
    case OutcomeSentiment.neutral:
      return <InfoIcon style={{ color: Colors.coolWhite }} />;
    case OutcomeSentiment.negative:
      return <CloseIcon style={{ color: Colors.coolWhite }} />;
    case OutcomeSentiment.positive:
      return <CheckCircleIcon style={{ color: Colors.coolWhite }} />;
    default:
      return <QuestionMarkIcon style={{ color: Colors.coolWhite }} />;
  }
};

function generateKey(path: string, token: string) {
  return `${path}-${token}`;
}
const API_URL = `${process.env.REACT_APP_BACKEND_URL}/api`;

/**
 * Wrapper around axios requests
 * Notably prevents duplicate get requests from being made simultaneously
 */
const ongoingGets: Map<string, Promise<AxiosResponse<any, any>>> = new Map();
export const api = {
  async get(path: string, token: string): Promise<AxiosResponse<any, any>> {
    const key = generateKey(path, token);

    // We expect path to start with a slash, but if it doesn't, add one
    const url = path.charAt(0) === "/" ? API_URL + path : API_URL + "/" + path;

    if (ongoingGets.has(key)) {
      return ongoingGets.get(key)!;
    } else {
      const promise = (async () => {
        try {
          const response = await axios.get(url, {
            headers: { Authorization: `Bearer ${token}` },
          });
          return response;
        } finally {
          ongoingGets.delete(key); // Clean up in the finally block
        }
      })();

      ongoingGets.set(key, promise);
      return promise;
    }
  },

  async post(path: string, token: string, data: unknown) {
    const url = path.charAt(0) === "/" ? API_URL + path : API_URL + "/" + path;
    return await axios.post(url, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  async patch(path: string, token: string, data: unknown) {
    const url = path.charAt(0) === "/" ? API_URL + path : API_URL + "/" + path;
    return await axios.patch(url, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  async delete(path: string, token: string) {
    const url = path.charAt(0) === "/" ? API_URL + path : API_URL + "/" + path;
    return await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};

/**
 * Converts a phone number from E.164 format or xxx-xxx-xxxx to a readable format `(area code) xxx-xxxx`.
 * 
 * @param phoneNumber - The phone number in E.164 format (`+1xxxxxxxxxx`, `1xxxxxxxxxx`, or `xxxxxxxxxx`)
 * @returns The phone number in the readable format `(area code) xxx-xxxx`
 */
export function convertE164ToReadable(phoneNumber: string): string {
  if (!phoneNumber) {
    return phoneNumber;
  }

  // Remove the leading plus sign and any non-numeric characters
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

  // Handle phone numbers with or without the country code
  let formattedNumber = cleanedPhoneNumber;
  if (cleanedPhoneNumber.length === 11 && cleanedPhoneNumber.startsWith('1')) {
    formattedNumber = cleanedPhoneNumber.substring(1);
  } else if (cleanedPhoneNumber.length === 11 && cleanedPhoneNumber.startsWith('01')) {
    formattedNumber = cleanedPhoneNumber.substring(2);
  } else if (cleanedPhoneNumber.length !== 10) {
    return phoneNumber;
  }

  // Extract the area code and the subscriber number
  const areaCode = formattedNumber.substring(0, 3);
  const subscriberNumber = formattedNumber.substring(3);

  // Format the phone number in the desired format
  return `(${areaCode}) ${subscriberNumber.substring(0, 3)}-${subscriberNumber.substring(3)}`;
}
