import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { AgentType, Agent, FrontendUser, EHR, WaitlistState, WaitlistRun } from "../types";
import { Typography, Switch, Modal, Tooltip, Button, Tabs, Tab, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SettingsIcon from "@mui/icons-material/Settings";
import SignoutHeader from "../subcomponents/SignoutHeader";
import ConfigureForm from "../forms/ConfigureForm";
import { SecondaryButton } from "../subcomponents/CustomButton";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { Colors } from "../Colors";
import { selectAgents, updateAgentThunk } from "../slices/AgentSlice";
import { useSnackbar } from "../providers/SnackbarProvider";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ConversationQueue from "../components/ConversationPage";
import { usePostHog } from "posthog-js/react";
import styled from "styled-components";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import Loader from "../subcomponents/Loader";
import { DateTime } from "luxon";
import { deleteRun, executeRun, fetchWaitlistRuns } from "../slices/WaitlistRunsSlice";
import { Sidebar } from "../styles/GeneralStyles";
import AddIcon from "@mui/icons-material/Add";
import TimerIcon from "@mui/icons-material/Timer";
import CheckIcon from "@mui/icons-material/Check";
import { getWaitlistPatients } from "../slices/PatientSlice";
import ExportWaitlistOutcomesModal from "../forms/ExportWaitlistOutcomesModal";
import IosShareIcon from "@mui/icons-material/IosShare";
import AddHealthieRunModal from "../components/modals/AddHealthieRunModal";
import AddWebptRunModal from "../components/modals/AddWebptRunModal";

const StyledLine = styled(Box)`
  border-left: 1px solid #e0e0e0;
  height: 90px;
  align-self: center;

  @media (max-width: 750px) {
    height: auto;
    border-left: none;
    border-top: 1px solid #e0e0e0;
    width: 90%;
    margin: 15px 0;
  }
`;

const WaitlistRunRow: React.FC<{ run: WaitlistRun; executeRun: any; deleteRun: any }> = ({ run, executeRun, deleteRun }) => {
  // TODO (Hizami): Use the actual enum values
  const { agentId } = useParams<{ agentId: string }>();
  const navigate = useNavigate();

  const runDateTime: DateTime = DateTime.fromISO(run.appointmentDate, { setZone: true });
  const runDay = runDateTime.toFormat("EEEE");
  const runDate = runDateTime.toFormat("MMMM d");
  const runTime = runDateTime.toFormat("h:mm a");

  const stateColor =
    run.state === WaitlistState.accepted
      ? Colors.success
      : [WaitlistState.declined, WaitlistState.noValidPatients, WaitlistState.bookingFailed, WaitlistState.expired].includes(run.state)
      ? Colors.error
      : Colors.grey1;

  const states = {
    [WaitlistState.accepted]: "Booked",
    [WaitlistState.declined]: "Declined",
    [WaitlistState.ongoing]: "Ongoing...",
    [WaitlistState.notStarted]: "Not started",

    [WaitlistState.noValidPatients]: "No valid patients",
    [WaitlistState.bookingFailed]: "Booking failed",
    [WaitlistState.expired]: "Expired",
  };

  const status = states[run.state as WaitlistState];

  return (
    <Box
      onClick={() => navigate(`/dashboard/agents/${agentId}/waitlist-runs/${run.waitlistRunId}`)}
      key={run.waitlistRunId}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "20px",
        backgroundColor: Colors.textfield,
        padding: { xs: "10px 15px", sm: "20px" },
        borderRadius: "25px",
        alignItems: "start",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.08)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography fontSize={"0.85rem"}>{runDay}</Typography>
        <Typography variant="h6" fontSize={"0.9rem"}>
          {runDate}, {runTime}
        </Typography>
        <Typography variant="body1" fontSize={"0.9rem"}>
          with {run.pcpName}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
        }}
      >
        <Box
          sx={{
            border: `1px solid rgba(${parseInt(stateColor.slice(1, 3), 16)}, ${parseInt(stateColor.slice(3, 5), 16)}, ${parseInt(
              stateColor.slice(5, 7),
              16
            )}, 0.08)`,
            borderRadius: "15px",
            padding: "4px 10px",
            background: `rgba(${parseInt(stateColor.slice(1, 3), 16)}, ${parseInt(stateColor.slice(3, 5), 16)}, ${parseInt(
              stateColor.slice(5, 7),
              16
            )}, 0.1)`,
            width: "fit-content",
          }}
        >
          <Tooltip title={run.stateJustification} arrow>
            <Typography variant="body2" color={stateColor}>
              {status}
            </Typography>
          </Tooltip>
        </Box>
        {run.bookedBy && (
          <React.Fragment>
            <Typography variant="body1" fontSize={"0.9rem"} mt={1}>
              Booked by:
            </Typography>
            <Typography variant="body1" fontSize={"0.9rem"} style={{ fontWeight: "bold" }}>
              {run.bookedBy.firstName} {run.bookedBy.lastName}
            </Typography>
          </React.Fragment>
        )}
        {run.state == "not-started" && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              executeRun(run.waitlistRunId);
            }}
          >
            Execute Run
          </button>
        )}
      </Box>

      {process.env.REACT_APP_ENV === "development" && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            deleteRun(run.waitlistRunId);
          }}
        >
          Delete Run
        </Button>
      )}
    </Box>
  );
};

const WaitlistAgentDisplay: React.FC<{ agent: Agent }> = ({ agent }) => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const organization = useSelector((state: RootState) => state.auth.organization);
  const waitlistRunIds = useSelector((state: RootState) => state.waitlistRuns.waitlistRunIds);
  const storeWaitlistRuns = useSelector((state: RootState) => state.waitlistRuns.waitlistRuns);
  const waitlistRuns = waitlistRunIds.map((id: string) => storeWaitlistRuns[id]);
  const loadingRuns = useSelector((state: RootState) => state.waitlistRuns.fetchingRunsLoading);
  const [exportModalOpen, setExportModalOpen] = React.useState(false);

  const [runModalOpen, setRunModalOpen] = React.useState(false);

  useEffect(() => {
    if (!user?.token) {
      return;
    }
    dispatch(fetchWaitlistRuns({ token: user?.token, agentId: agent.agentId, quietly: false }));
  }, []);

  useEffect(() => {
    const fetchData = () => {
      if (!user?.token) {
        return;
      }
      dispatch(fetchWaitlistRuns({ token: user?.token, agentId: agent.agentId, quietly: true }));
    };
    fetchData();
  }, [dispatch, agent.agentId]);

  const onRunClose = () => {
    setRunModalOpen(false);
  };

  const [selectedRunsTab, setSelectedRunsTab] = React.useState(0);

  const successfulRuns = waitlistRuns.filter((run) => run.state === WaitlistState.accepted);
  const failedRuns = waitlistRuns.filter((run) => [WaitlistState.declined, WaitlistState.bookingFailed, WaitlistState.expired].includes(run.state));
  const emptyRuns = waitlistRuns.filter((run) => run.state === WaitlistState.noValidPatients);
  const ongoingRuns = waitlistRuns.filter((run) => run.state === WaitlistState.ongoing);
  const notStartedRuns = waitlistRuns.filter((run) => run.state === WaitlistState.notStarted);

  const endedRuns =
    selectedRunsTab === 0
      ? successfulRuns
      : selectedRunsTab === 1
      ? failedRuns
      : selectedRunsTab === 2
      ? emptyRuns
      : waitlistRuns.filter((run) => run.state !== WaitlistState.ongoing && run.state !== WaitlistState.notStarted);

  const [selectedTab, setSelectedTab] = React.useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const renderTabContent = (type: "outcomes" | "queue") => {
    if (!user?.token) {
      return;
    }
    if (type === "outcomes") {
      return (
        <Box sx={tabBoxStyles}>
          <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ marginBottom: "15px" }}>
            <Box display="flex" alignItems="center">
              <Typography variant="h6">Waitlist Run Outcomes</Typography>
              <Tabs value={selectedRunsTab} onChange={(e, newValue) => setSelectedRunsTab(newValue)}>
                <Tab label="Successful" style={{ textTransform: "none", color: "#FFF" }} />
                <Tab label="Unsuccessful" style={{ textTransform: "none", color: "#FFF" }} />
                <Tab label="Empty" style={{ textTransform: "none", color: "#FFF" }} />
                <Tab label="All" style={{ textTransform: "none", color: "#FFF" }} />
              </Tabs>
            </Box>
            <Tooltip title="Export Waitlist Runs">
              <Button
                variant="text"
                onClick={() => setExportModalOpen(true)}
                sx={{ color: Colors.primary, display: "flex", justifyContent: "center" }}
              >
                <IosShareIcon />
              </Button>
            </Tooltip>
          </Box>

          {loadingRuns && <LoadingWithMessage message="Loading runs..." size={40} customStyles={{ marginTop: "20px" }} />}

          {endedRuns.length > 0 ? (
            endedRuns.map((run: any) => (
              <WaitlistRunRow
                key={run.waitlistRunId}
                run={run}
                executeRun={() => dispatch(executeRun({ token: user.token!, runId: run.waitlistRunId }))}
                deleteRun={() => dispatch(deleteRun({ token: user.token!, runId: run.waitlistRunId }))}
              />
            ))
          ) : (
            <Typography variant="body1" sx={{ textAlign: "center", color: Colors.info }}>
              No waitlist runs completed yet
            </Typography>
          )}

          {/* Render ExportWaitlistOutcomesModal when exportModalOpen is true */}
          {exportModalOpen && (
            <Modal
              open={exportModalOpen}
              onClose={() => setExportModalOpen(false)}
              aria-labelledby="export-modal-title"
              aria-describedby="export-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "80%",
                  maxWidth: 600,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 2,
                  outline: "none",
                }}
              >
                <ExportWaitlistOutcomesModal agentsSelector={false} mainAgentsFilter={[agent.agentId]} onClose={() => setExportModalOpen(false)} />
              </Box>
            </Modal>
          )}
        </Box>
      );
    } else if (type === "queue") {
      if (!user?.token) {
        return;
      }
      return (
        <Box sx={tabBoxStyles}>
          <Box display="flex" alignItems="center" sx={{ marginBottom: "15px" }}>
            <Typography variant="h6">Waitlist Run Queue</Typography>
            <Tooltip title="Create run">
              <AddIcon
                onClick={() => setRunModalOpen(true)}
                style={{
                  color: Colors.primary,
                  cursor: "pointer",
                  marginLeft: "5px",
                }}
              />
            </Tooltip>
          </Box>

          {loadingRuns && <LoadingWithMessage message="Loading runs..." size={40} customStyles={{ marginTop: "20px" }} />}
          {ongoingRuns.concat(notStartedRuns).length > 0 ? (
            ongoingRuns
              .concat(notStartedRuns)
              .map((run: any) => (
                <WaitlistRunRow
                  key={run.waitlistRunId}
                  run={run}
                  executeRun={() => dispatch(executeRun({ token: user.token!, runId: run.waitlistRunId }))}
                  deleteRun={() => dispatch(deleteRun({ token: user.token!, runId: run.waitlistRunId }))}
                />
              ))
          ) : (
            <Typography variant="body1" sx={{ textAlign: "center", color: Colors.info }}>
              No waitlist runs yet
            </Typography>
          )}
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={{ width: "100%", color: "#FFF" }}>
      {isSmallScreen ? (
        <>
          <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)} style={{ marginBottom: "10px" }} centered>
            <Tab label="Outcomes" style={{ textTransform: "none", color: "#FFF" }} />
            <Tab label="Queue" style={{ textTransform: "none", color: "#FFF" }} />
          </Tabs>
          {renderTabContent(selectedTab === 0 ? "outcomes" : "queue")}
        </>
      ) : (
        <Box sx={{ display: "flex", gap: "20px" }}>
          {renderTabContent("outcomes")}
          {renderTabContent("queue")}
        </Box>
      )}
      <>
        <AddHealthieRunModal open={!!organization?.integrations.includes(EHR.healthie) && runModalOpen} onClose={onRunClose} />
        <AddWebptRunModal open={!!organization?.integrations.includes(EHR.webpt) && runModalOpen} onClose={onRunClose} />
      </>
    </Box>
  );
};

// Extract common styles for each tab box
const tabBoxStyles = {
  backgroundColor: Colors.bg2,
  borderRadius: "15px",
  padding: "20px",
  width: "100%",
  height: "100%",
};

const SchedulingAgentDisplay: React.FC = () => {
  return <ConversationQueue />;
};

const AgentInfoPage: React.FC = () => {
  const { agentId } = useParams<{ agentId: string }>();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const agent: Agent | null = useSelector(selectAgents).find((agent) => agent.agentId === agentId) || null;
  const loading = useSelector((state: RootState) => state.agents.loading);

  const dispatch = useAppDispatch();

  const { bookedApptVolume, bookedApptRate, avgTimeToFillAppt } = agent || {};
  const posthog = usePostHog();

  const navigate = useNavigate();
  const { showMessage } = useSnackbar();

  const [isActive, setIsActive] = React.useState(agent?.isActive || false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  useEffect(() => {
    const fetchData = () => {
      if (!user?.token || !agent?.agentId) {
        return;
      }
      dispatch(fetchWaitlistRuns({ token: user?.token, agentId: agent?.agentId!, quietly: true }));
      dispatch(getWaitlistPatients({ token: user?.token!, quietly: true }));
    };
    fetchData();
  }, [dispatch, agent?.agentId]);

  const handleToggle = () => {
    if (!user?.token) {
      showMessage("Session is expired. Please refresh.", "error");
      return;
    }
    dispatch(updateAgentThunk({ token: user.token, agent: { agentId: agentId, isActive: !isActive } }));
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (agent?.agentType === AgentType.inbound) {
      navigate(`/dashboard/agents/${agentId}/agent-conversation-history`);
    }
  }, [agent?.agentType]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleViewAgentConvo = () => {
    navigate(`/dashboard/agents/${agentId}/agent-conversation-history`);
  };

  const handleBack = () => {
    posthog?.capture("Agent Info Page - Back");
    navigate(`/dashboard/agents`);
  };

  useEffect(() => {
    if (!loading && !agent) {
      navigate("/dashboard/agents");
    }
  }, [loading, agent]);

  return agent ? (
    <Box sx={{ margin: { xs: "70px 0", lg: "0" } }}>
      <SignoutHeader />
      <SecondaryButton onClick={handleBack} sx={{ display: { xs: "none", lg: "flex" }, alignItems: "center", margin: "20px 0" }}>
        <ArrowBackIcon sx={{ marginRight: "10px" }} /> Back
      </SecondaryButton>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: { sm: "75px", lg: "0" } }}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: { xs: "5px", sm: "15px" } }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", gap: 1 }}>
            <Typography variant="h4" sx={{ fontSize: { xs: "1.5rem", sm: "2.25rem" }, color: "#FFF" }}>
              {agent.name}
            </Typography>
          </Box>
          <Switch
            checked={isActive}
            onChange={handleToggle}
            sx={{
              "& .MuiSwitch-switchBase": {
                color: "#FFFFFF",
                "& + .MuiSwitch-track": {
                  backgroundColor: "#CCCCCC",
                },
              },
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "#FFFFFF",
                "& + .MuiSwitch-track": {
                  backgroundColor: "#00AC4F",
                },
              },
            }}
          />
          <Box sx={{ display: { xs: "none", sm: "block" }, color: "#FFF" }}>
            Agent is <span style={{ fontWeight: "bold" }}>{isActive ? "on" : "off"}</span>
          </Box>
        </Box>
        <SecondaryButton onClick={handleOpenModal} sx={{ padding: { xs: "0", sm: "10px 20px" } }}>
          <SettingsIcon sx={{ marginRight: { xs: "0", sm: "7px" } }} />
          <Typography variant="h6" sx={{ fontSize: { xs: "0.8rem", sm: "1rem" }, display: { xs: "none", sm: "block" } }}>
            {" "}
            Configure{" "}
          </Typography>
        </SecondaryButton>
      </Box>

      <Box sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: { xs: "1rem", sm: "1.4rem" },
            lineHeight: { xs: "1", sm: "1.5" },
          }}
        >
          <span style={{ color: "grey", fontSize: "1.3rem" }}>{agent.description}</span>
        </Typography>
      </Box>

      <Typography
        onClick={handleViewAgentConvo}
        variant="body1"
        textAlign="right"
        sx={{ marginTop: "10px", color: Colors.primary, fontSize: { xs: "0.9rem", sm: "1rem" }, cursor: "pointer" }}
      >
        View conversation history
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "space-evenly" },
          flexDirection: { xs: "column", sm: "row" },
          borderRadius: "20px",
          margin: { xs: "10px 0px 25px 0px", md: "10px 10px 25px 10px" },
          padding: { xs: "20px 0", sm: "20px", md: "20px 0" },
          background: Colors.bg2,
          boxShadow: { xs: "0 4px 6px rgba(0, 0, 0, 0.15)", sm: "none" },
        }}
      >
        <Box sx={{ display: "flex", gap: { xs: "15%", sm: "7px", lg: "15px" }, alignItems: "center", marginLeft: { xs: "10%", sm: "0" } }}>
          <Box>
            <EventAvailableIcon sx={{ fontSize: "5rem", color: "#00AC4F", background: "#D3FFE7", padding: "20px", borderRadius: "50%" }} />
          </Box>
          <Box sx={{ lineHeight: "0" }}>
            <Typography variant="body1" sx={{ color: Colors.grey3, fontSize: { xs: "0.9rem", lg: "1rem" } }}>
              Booked appointments
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "2.25rem", lineHeight: "1.2", color: "#FFF" }}>
              {bookedApptVolume}
            </Typography>
          </Box>
        </Box>
        <StyledLine />
        <Box sx={{ display: "flex", gap: { xs: "15%", sm: "7px", lg: "15px" }, alignItems: "center", marginLeft: { xs: "10%", sm: "0" } }}>
          <CheckIcon sx={{ fontSize: "5rem", color: "#0F5FC2", background: "#CAF1FF", padding: "20px", borderRadius: "50%" }} />
          <Box sx={{ lineHeight: "0" }}>
            <Typography variant="body1" sx={{ color: Colors.grey3, fontSize: { xs: "0.9rem", lg: "1rem" } }}>
              Booking Rate
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "2.25rem", lineHeight: "1.2", color: "#FFF" }}>
              {bookedApptRate !== undefined ? (bookedApptRate * 100).toFixed(2) : "0.00"}%
            </Typography>
          </Box>
        </Box>
        <StyledLine />
        <Box sx={{ display: "flex", gap: { xs: "15%", sm: "7px", lg: "15px" }, alignItems: "center", marginLeft: { xs: "10%", sm: "0" } }}>
          <TimerIcon sx={{ fontSize: "5rem", color: "#FFA500", background: "#FFEFD5", padding: "20px", borderRadius: "50%" }} />
          <Box sx={{ lineHeight: "0" }}>
            <Typography variant="body1" sx={{ color: Colors.grey3, fontSize: { xs: "0.9rem", lg: "1rem" } }}>
              Average Time to Fill
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "2.25rem", lineHeight: "1.2", color: "#FFF" }}>
              {avgTimeToFillAppt?.toFixed(2)} min
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        margin={0}
        sx={{
          width: "100%",
          "& > *": {
            margin: 0,
            flex: "1 1 0",
            borderRadius: "20px",
            padding: "10px",
          },
        }}
      >
        {agent.agentType !== AgentType.waitlist && agent.agentType !== AgentType.inbound && <SchedulingAgentDisplay />}
        {agent.agentType === AgentType.waitlist && <WaitlistAgentDisplay agent={agent} />}
      </Box>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Sidebar className={isModalOpen ? "open" : ""}>
          <ConfigureForm agent={agent} isModalOpen={isModalOpen} onClose={handleCloseModal} />
        </Sidebar>
      </Modal>
    </Box>
  ) : (
    <Box>
      <SignoutHeader />
      {loading ? (
        <Loader message="" size={60} customStyles={{ height: "100%", marginTop: "30vh" }} />
      ) : (
        <Typography variant="h2" textAlign="center">
          No agent found
        </Typography>
      )}
    </Box>
  );
};

export default AgentInfoPage;
