import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { EHR, FrontendUser, HealthieAppointment, RawHealthiePatient, HealthieProvider, RawWebptPatient } from "../types";
import { sessionExpired } from "./SessionSlice";
import { getSession } from "./AuthSlice";
import { RootState } from "../store";
import { api } from "../utils/utils";

export interface WebptIntegrationState {
  patients: RawWebptPatient[];
  providers: { id: string; name: string }[];
  error: string | null;
  loadingPatients: boolean;
  loadingProviders: boolean;
}

const initialState: WebptIntegrationState = {
  patients: [],
  providers: [],
  error: null,
  loadingPatients: false,
  loadingProviders: false,
};

export const fetchWebptPatients = createAsyncThunk<{ patients: RawWebptPatient[] }, { token: string }, { rejectValue: Error }>(
  "/integrations/fetchPatients",
  async ({ token }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/webpt/patients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data) {
        throw new Error("No data received");
      }
      return { patients: response.data };
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch patients: " + (error as Error).message));
    }
  }
);

export const fetchWebptProviders = createAsyncThunk<{ providers: { id: string; name: string }[] }, { token: string }, { rejectValue: Error }>(
  "/integrations/fetchProviders",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/webpt/providers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data) {
        throw new Error("No data received");
      }
      return { providers: response.data };
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch providers: " + (error as Error).message));
    }
  }
);

const webptIntegrationSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebptPatients.pending, (state) => {
        state.loadingPatients = true;
      })
      .addCase(fetchWebptPatients.fulfilled, (state, action) => {
        state.patients = action.payload.patients;
        state.loadingPatients = false;
      })
      .addCase(fetchWebptPatients.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch patients";
        state.loadingPatients = false;
      })
      .addCase(fetchWebptProviders.pending, (state) => {
        state.loadingProviders = true;
      })
      .addCase(fetchWebptProviders.fulfilled, (state, action) => {
        state.providers = action.payload.providers;
        state.loadingProviders = false;
      })
      .addCase(fetchWebptProviders.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch providers";
        state.loadingProviders = false;
      });
  },
});

export default webptIntegrationSlice.reducer;
