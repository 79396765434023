import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, IconButton, InputAdornment, Tooltip, Switch } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import { Webhook, FrontendUser } from "../types";
import { RootState, useAppDispatch } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../subcomponents/CustomButton";
import {
  clearWebhooks,
  refreshWebhook,
  addWebhook,
  fetchWebhooks,
  deleteWebhook,
  toggleWebhookActivity,
  toggleWebhookRecording,
} from "../slices/WebhookSlice";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { api } from "../utils/utils";
import { Colors } from "../Colors";

const Logs: React.FC = () => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [logs, setLogs] = useState<any[]>([]);

  const dispatch = useAppDispatch();

  const fetchLogs = async () => {
    if (!user || !user.token) {
      return;
    }

    setLoading(true);
    const response = await api.get(`/log-items`, user.token);
    console.log(response);
    setLogs(response.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchLogs();
  }, [user?.token]);

  return (
    <Box
      sx={{
        marginTop: { xs: "75px", sm: 0, color: "#FFF" },
        "& .MuiOutlinedInput-root": { color: "#FFFFFF" },
        "& .MuiInputLabel-root": { color: Colors.info },
        "& .MuiInputBase-input": { color: "#FFFFFF" },
        "& .MuiSvgIcon-root": { color: "#FFF" },
        "& .MuiAutocomplete-paper": { backgroundColor: Colors.bg3, margin: 0 },
        "& .MuiAutocomplete-listbox": { backgroundColor: Colors.bg3 },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
        <Typography variant="h6">Log Items</Typography>
      </Box>
      {loading && <LoadingWithMessage message="Fetching logs..." size={60} />}
      {logs.length === 0 && !loading && <Typography variant="body1">No logs found.</Typography>}
      {logs.length > 0 && !loading && logs.map((log, index) => <Typography key={index}>{log.logDescription}</Typography>)}
    </Box>
  );
};

export default Logs;
