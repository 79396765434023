import React from "react";
import { Modal, Box, Typography, FormControl, InputLabel, Select, MenuItem, Button, TextField } from "@mui/material";
import { EHR } from "../../types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { DateTime } from "luxon";
import { DateTimePicker } from "@mui/x-date-pickers";
import { createRun } from "../../slices/WaitlistRunsSlice";
import { ModalBoxVariant1 } from "../../styles/GeneralStyles";

type AddWebptRunModalProps = {
  open: boolean;
  onClose: () => void;
};

const AddWebptRunModal: React.FC<AddWebptRunModalProps> = ({ open, onClose }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const { agentId } = useParams<{ agentId: string }>();
  const [localErrorMessage, setLocalErrorMessage] = React.useState<string>("");
  const [appointmentDate, setAppointmentDate] = React.useState<DateTime | null>(null);
  const [duration, setDuration] = React.useState<number>(30);
  const [providerId, setProviderId] = React.useState<string>("");
  const dispatch = useAppDispatch();

  const providers = useSelector((state: RootState) => state.webptIntegration.providers);

  const handleCreateRun = async () => {
    if (!user?.token) {
      return;
    }
    const provider = providers.find((provider) => provider.id === providerId);
    if (!provider) {
      setLocalErrorMessage("Please select a provider");
      return;
    }

    await dispatch(
      createRun({
        token: user.token,
        params: {
          appointmentDate: appointmentDate?.toISO() ?? "",
          ehrParams: {
            providerId: providerId,
            pcpName: provider.name,
            appointmentDuration: duration,
            ehr: EHR.webpt,
          },
        },
      })
    );
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBoxVariant1>
        <Typography variant="h4" gutterBottom>
          Create Run
        </Typography>

        <Typography variant="body1" gutterBottom>
          Select a time
        </Typography>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", mb: 2 }}>
          <DateTimePicker
            label="Appointment date"
            value={appointmentDate}
            onChange={setAppointmentDate}
          />
        </Box>

        <Typography variant="body1" gutterBottom>
          Appointment Duration
        </Typography>
        <TextField
          type="number"
          value={duration}
          onChange={(e) => setDuration(Number(e.target.value))}
          fullWidth
          margin="normal"
          variant="outlined"
        />

        <Typography variant="body1" gutterBottom>
          Select a provider
        </Typography>

        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="provider-select-label">Select a provider</InputLabel>
          <Select
            labelId="provider-select-label"
            value={providerId}
            onChange={(e) => setProviderId(e.target.value)}
            label="Select a provider"
          >
            <MenuItem value="">
              <em>Select a provider</em>
            </MenuItem>
            {providers.map((provider) => (
              <MenuItem key={provider.id} value={provider.id}>
                {provider.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button variant="contained" color="primary" onClick={handleCreateRun}>
          Add Run
        </Button>
      </ModalBoxVariant1>
    </Modal>
  );
};

export default AddWebptRunModal;
