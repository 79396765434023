import React, { useState, useEffect} from "react";
import Box from "@mui/material/Box";
import SignoutHeader from "../subcomponents/SignoutHeader";
import Typography from "@mui/material/Typography";
import { PrimaryButton } from "../subcomponents/CustomButton";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { Consent, EHR, FrontendUser, HealthieProvider, OmniPatient, Organization, PatientGroup } from "../types";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputBase,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { dropdownStyles, menuPropsStyles, Sidebar } from "../styles/GeneralStyles";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import UpdateHealthiePatientModal from "../forms/UpdatePatientModal";
import { UpdateWebptPatientModal } from "../forms/UpdatePatientModal";
import HealthieAddPatientModal from "../forms/AddPatientModal";
import { WebptAddPatientModal } from "../forms/AddPatientModal";
import { RootState, useAppDispatch } from "../store";
import { useSelector } from "react-redux";
import { Colors } from "../Colors";
import { api, convertE164ToReadable, formatIsoToCustomDateStringWithEEEE } from "../utils/utils";
import { deletePatient, getWaitlistPatients, updatePriorities } from "../slices/PatientSlice";
import { FooterBox, HeaderBox } from "../subcomponents/SidebarComponents";
import { group } from "console";
import { getSession, setOrganizationAttribute, updateOrganizationAttribute } from "../slices/AuthSlice";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/Warning";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import PriorityModal from "../components/PriorityModal";
import CustomAutocomplete from "../components/CustomAutocomplete";

const PatientCard: React.FC<{ omniPatient: OmniPatient; updatePatient: (id: string) => void; updateOpen: boolean }> = ({
  omniPatient,
  updatePatient,
  updateOpen,
}) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);
  const loadingDeletePatient: boolean = useSelector((state: RootState) => state.patients.loadingDeletePatient);
  const loadingUpdatePatient: boolean = useSelector((state: RootState) => state.patients.loadingUpdatePatient);
  const dispatch = useAppDispatch();
  const groups = organization?.patientGroups || [];

  const providers: HealthieProvider[] = useSelector((state: RootState) => state.healthieIntegration.providers);
  const loadingProviders: boolean = useSelector((state: RootState) => state.healthieIntegration.loadingProviders);

  const { patientGroupId, availableDates } = omniPatient.waitlist;

  const groupName = groups.find((group) => group.patientGroupId === patientGroupId)?.name;

  const { patientId, firstName, lastName, phoneNumber } = omniPatient.patient;
  const ehrId = omniPatient[EHR.healthie]?.healthiePatientId || omniPatient[EHR.webpt]?.webptPatientId;

  const consentHistory = omniPatient.patient.consentHistory;
  const latestConsent = consentHistory[consentHistory.length - 1];
  const isRevoked = latestConsent.consent === Consent.revoked;
  const lastConsentMessage = latestConsent.message;
  const lastConsentDate = DateTime.fromISO(latestConsent.date).toLocaleString(DateTime.DATE_MED);

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: "rgba(256, 256, 256, 0.04)",
        borderRadius: "15px",
        padding: "12px",
        margin: "20px 0",
        position: "relative",
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {/* Left Side Content */}
        <Box>
          <Typography
            onClick={() => navigate(`/dashboard/patients/${patientId}`)}
            variant="h6"
            fontWeight="bold"
            fontSize="1rem"
            sx={{ cursor: "pointer" }}
          >
            {firstName} {lastName}
          </Typography>
          <Box color={Colors.info} display="flex" alignItems="center" gap="10px">
            <Typography variant="body2">{convertE164ToReadable(phoneNumber)}</Typography>
            <CircleIcon sx={{ fontSize: "5px" }} />
            <Typography variant="body2">{`ID: ${ehrId}`}</Typography>
            <CircleIcon sx={{ fontSize: "5px" }} />
            { omniPatient?.[EHR.healthie] && loadingProviders && (
              <Typography variant="body2">
                <CircularProgress size={15} style={{ color: 'white' }} /> 
              </Typography>
            )}
            { omniPatient?.[EHR.healthie] && !loadingProviders && (
              <Typography variant="body2">{`Provider: ${omniPatient[EHR.healthie].providerIds.map((providerId: string) => {
                const provider: HealthieProvider | undefined = providers.find((provider) => provider.id === providerId);
                return (provider?.firstName && provider?.lastName) ? `${provider.firstName} ${provider.lastName}` : providerId;
              }).join(", ")}`}
              </Typography>
            )}
            {groupName && (
              <>
                <CircleIcon sx={{ fontSize: "5px" }} />
                <Typography variant="body2">{`Group: ${groupName}`}</Typography>
              </>
            )}
          </Box>
        </Box>

        {/* Right Side Icons */}
        <Box display="flex" alignItems="center">
          {isRevoked && (
            <Tooltip
              title={`This patient has placed themselves on the Do Not Call list via the request "${lastConsentMessage}" on ${lastConsentDate}`}
              arrow
            >
              <WarningIcon
                sx={{
                  color: "red",
                  opacity: 0.8,
                  marginRight: "10px", // Space between WarningIcon and CloseIcon
                  transition: "opacity 0.3s",
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            </Tooltip>
          )}

          {!updateOpen && (
            <Tooltip title="Archive Patient" arrow>
              <IconButton
                className="close-icon"
                disabled={loadingDeletePatient || loadingUpdatePatient}
                sx={{
                  color: "#FFF",
                  padding: "5px",
                  visibility: loadingDeletePatient || loadingUpdatePatient ? "hidden" : "visible",
                  opacity: 0.8,
                  transition: "opacity 0.3s",
                  "&:hover": {
                    opacity: 1,
                  },
                }}
                onClick={async () => {
                  if (!user?.token) {
                    return;
                  }
                  if (!loadingDeletePatient && !loadingUpdatePatient) {
                    await dispatch(deletePatient({ token: user.token, patientId }));
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>

      <Typography variant="body2" fontWeight="bold" mt={2}>
        Availability:
      </Typography>
      <Typography variant="body2" color={Colors.info}>
        {availableDates.map((date) => formatIsoToCustomDateStringWithEEEE(date)).join(", ")}
      </Typography>
      {availableDates.length === 0 && (
        <Typography variant="body2" color={Colors.info}>
          No available dates
        </Typography>
      )}
      <Typography
        onClick={() => {
          updatePatient(patientId);
        }}
        color="primary"
        fontWeight="bold"
        sx={{ fontSize: "0.8rem", marginTop: "10px", cursor: "pointer" }}
      >
        Update Patient
      </Typography>
    </Box>
  );
};
interface AddGroupModalProps {
  patients: OmniPatient[];
  onSuccess: () => void;
  onClose: () => void;
}

const AddGroupModal: React.FC<AddGroupModalProps> = ({ patients, onSuccess, onClose }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [selectedPatients, setSelectedPatients] = useState<string[]>([]);
  const [groupName, setGroupName] = useState<string>("");
  const patientOptions = patients.map((patient) => ({ value: patient.patientId, label: `${patient.patient.firstName} ${patient.patient.lastName}` }));

  const createGroup = async () => {
    if (!user?.token) return;
    await api.post("/patients/groups", user.token, { name: groupName, patientIds: selectedPatients });
    onSuccess();
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "100%",
        paddingBottom: "60px",
        paddingRight: "20px",
        "& .MuiOutlinedInput-root": { color: "#FFFFFF" },
        "& .MuiInputLabel-root": { color: Colors.info },
        "& .MuiInputBase-input": { color: "#FFFFFF" },
        "& .MuiSvgIcon-root": { color: "#FFF" },
        "& .MuiAutocomplete-paper": { backgroundColor: Colors.bg3, margin: 0 },
        "& .MuiAutocomplete-listbox": { backgroundColor: Colors.bg3 },
      }}
    >
      <HeaderBox title="Add Group" onClose={onClose} />

      <Box mt={"60px"}>
        <Typography variant="body1">Group Name</Typography>
        <FormControl fullWidth>
          <TextField fullWidth label="Group Name" variant="outlined" value={groupName} onChange={(e) => setGroupName(e.target.value)} />
        </FormControl>
      </Box>

      <CustomAutocomplete options={patientOptions} selectedItems={selectedPatients} setSelectedItems={setSelectedPatients} label="Patients" />

      {selectedPatients.length > 0 && (
        <Box>
          <Typography variant="body1" mt={2}>
            Selected Patients:
          </Typography>
          <Box sx={{ maxHeight: "400px", overflowY: "auto", border: "1px solid #ddd", padding: "8px" }}>
            {selectedPatients.map((patientId) => {
              const patient = patients.find((patient) => patient.patientId === patientId);
              return (
                patient && (
                  <Box key={patientId}>
                    {patient.patient.firstName} {patient.patient.lastName}
                  </Box>
                )
              );
            })}
          </Box>
        </Box>
      )}

      {<FooterBox onClick={createGroup} disabled={!groupName} buttonText="Add Group" loading={false} />}
    </Box>
  );
};

interface UpdateGroupModalProps {
  patients: OmniPatient[];
  groupPatientIds: string[];
  patientGroup: PatientGroup;
  onSuccess: () => void;
  onClose: () => void;
}

const UpdateGroupModal: React.FC<UpdateGroupModalProps> = ({ patients, groupPatientIds, patientGroup, onSuccess, onClose }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const [selectedPatients, setSelectedPatients] = useState<string[]>(groupPatientIds);
  const [groupName, setGroupName] = useState<string>(patientGroup.name);
  const patientOptions: { value: string; label: string }[] = patients.map((patient) => ({ value: patient.patientId, label: `${patient.patient.firstName} ${patient.patient.lastName}` }));

  const updateGroup = async () => {
    if (!user?.token) return;
    await api.patch(`/patients/groups/${patientGroup.patientGroupId}`, user.token, { name: groupName, patientIds: selectedPatients });
    onSuccess();
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "100%",
        paddingBottom: "60px",
        paddingRight: "20px",
        "& .MuiOutlinedInput-root": { color: "#FFFFFF" },
        "& .MuiInputLabel-root": { color: Colors.info },
        "& .MuiInputBase-input": { color: "#FFFFFF" },
        "& .MuiSvgIcon-root": { color: "#FFF" },
        "& .MuiAutocomplete-paper": { backgroundColor: Colors.bg3, margin: 0 },
        "& .MuiAutocomplete-listbox": { backgroundColor: Colors.bg3 },
      }}
    >
      <HeaderBox title="Update Group" onClose={onClose} />

      <Box mt={"60px"}>
        <Typography variant="body1" pb={2}>Group Name</Typography>
        <FormControl fullWidth>
          <TextField fullWidth label="Group Name" variant="outlined" value={groupName} onChange={(e) => setGroupName(e.target.value)} />
        </FormControl>
      </Box>

      <CustomAutocomplete 
        options={patientOptions} 
        selectedItems={selectedPatients} 
        setSelectedItems={setSelectedPatients} 
        label="Select patients"
        sx={{ marginTop: "5px" }}
      />

      {selectedPatients.length > 0 && (
        <Box sx={{ marginBottom: "20px" }}>
          <Typography variant="body1" mt={2} pb={1}>
            Selected Patients:
          </Typography>
          <Box sx={{ maxHeight: "400px", overflowY: "auto", border: "1px solid #ddd", padding: "8px" }}>
            {selectedPatients.map((patientId) => {
              const patient = patients.find((patient) => patient.patientId === patientId);
              return (
                patient && (
                  <Box key={patientId}>
                    {patient.patient.firstName} {patient.patient.lastName}
                  </Box>
                )
              );
            })}
          </Box>
        </Box>
      )}

      {<FooterBox onClick={updateGroup} disabled={!groupName} buttonText="Update Group" loading={false} />}
    </Box>
  );
};

const Patients: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const loadingPatients = useSelector((state: RootState) => state.patients.loadingFetchPatients);
  const loadingDeletePatient: boolean = useSelector((state: RootState) => state.patients.loadingDeletePatient);
  const loadingUpdatePatient: boolean = useSelector((state: RootState) => state.patients.loadingUpdatePatient);

  const organization = useSelector((state: RootState) => state.auth.organization);
  const groups = organization?.patientGroups || [];

  const [addPatientModalOpen, setAddPatientModalOpen] = useState<boolean>(false);

  const [patientPriorityModalOpen, setPatientPriorityModalOpen] = useState(false);
  const [groupPriorityModalOpen, setGroupPriorityModalOpen] = useState(false);
  const patients = useSelector((state: RootState) => state.patients.patients);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [updateGroupOpen, setUpdateGroupOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<any | undefined>(undefined);
  const [addGroupModalOpen, setAddGroupModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<PatientGroup | undefined>(undefined);
  const [selectedGroupPatientIds, setSelectedGroupPatientIds] = useState<string[] | undefined>(undefined);
  const [addLoadingGroups, setAddLoadingGroups] = useState(false);

  const [query, setQuery] = useState<string>("");

  const dispatch = useAppDispatch();

  const webptPatients = useSelector((state: RootState) => state.webptIntegration.patients);

  // remove all special characters and spaces
  function simplifyString(str: string) {
    return str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  }

  function filterPatients(patients: OmniPatient[], query: string) {
    return patients.filter((patient) => {
      const ehrId = patient[EHR.healthie]?.healthiePatientId || patient[EHR.webpt]?.webptPatientId || "";
      const patientString = simplifyString(`${patient.patient.firstName} ${patient.patient.lastName} ${patient.patient.phoneNumber} ${ehrId}`);
      const simpleQuery = simplifyString(query);
      return patientString.includes(simpleQuery);
    });
  }

  const filteredPatients = filterPatients(patients, query);

  useEffect(() => {
    if (user?.token) {
      dispatch(getWaitlistPatients({ token: user.token, quietly: true }));
    }
  }, [user?.token]);

  const onPatientPriorityClose = async (patients: OmniPatient[]) => {
    if (!user?.token) {
      return;
    }
    setPatientPriorityModalOpen(false);
    await updatePatientPriorities(patients);
    await dispatch(getWaitlistPatients({ token: user.token, quietly: false }));
  };

  const onGroupPriorityClose = async (groups: PatientGroup[]) => {
    if (!user?.token) {
      return;
    }
    setGroupPriorityModalOpen(false);
    await dispatch(
      updateOrganizationAttribute({ token: user.token, attribute: "patientGroupIds", value: groups.map((group) => group.patientGroupId) })
    );
    dispatch(setOrganizationAttribute({ attribute: "patientGroups", value: groups }));
  };

  const updatePatientPriorities = async (patients: OmniPatient[]) => {
    if (!user?.token) {
      return;
    }
    const patientIds = patients.map((patient: any) => patient.patientId);
    await dispatch(updatePriorities({ token: user?.token!, patientIds: patientIds }));
  };

  const updatePatient = async (patientId: string) => {
    setSelectedPatient(patients.find((patient: OmniPatient) => patient.patientId === patientId));
    setUpdateOpen(true);
  };

  const refreshGroups = async () => {
    if (user?.token) {
      setAddLoadingGroups(true);
      await dispatch(getWaitlistPatients({ token: user.token, quietly: true }));
      await dispatch(getSession());
      setAddLoadingGroups(false);
    }
  };

  const onAddGroupModalClose = async () => {
    setAddGroupModalOpen(false);
    await refreshGroups();
  };

  const onUpdateGroupModalClose = async () => {
    setUpdateGroupOpen(false);
    await refreshGroups();
  };

  const onSelectGroup = (group: PatientGroup) => {
    setSelectedGroup(group);
    setSelectedGroupPatientIds(
      patients.filter((patient) => patient.waitlist.patientGroupId === group.patientGroupId).map((patient) => patient.patientId)
    );
    setUpdateGroupOpen(true);
  };

  const deleteGroup = async (group: PatientGroup) => {
    if (!user?.token) {
      return;
    }
    await api.delete(`/patients/groups/${group.patientGroupId}`, user.token);
    await dispatch(getWaitlistPatients({ token: user.token, quietly: true }));
    await dispatch(getSession());
  };

  return (
    <Box
      sx={{
        marginTop: { xs: "75px", sm: 0, color: "#FFF" },
        "& .MuiOutlinedInput-root": { color: "#FFFFFF" },
        "& .MuiInputLabel-root": { color: Colors.info },
        "& .MuiInputBase-input": { color: "#FFFFFF" },
        "& .MuiSvgIcon-root": { color: "#FFF" },
        "& .MuiAutocomplete-paper": { backgroundColor: Colors.bg3, margin: 0 },
        "& .MuiAutocomplete-listbox": { backgroundColor: Colors.bg3 },
      }}
    >
      <SignoutHeader />
      {loadingPatients && <LoadingWithMessage message="Loading patients..." size={20} customStyles={{ marginTop: "20px" }} />}
      {loadingDeletePatient && <LoadingWithMessage message="Deleting patient..." size={20} customStyles={{ marginTop: "20px" }} />}
      {loadingUpdatePatient && <LoadingWithMessage message="Updating patient..." size={20} customStyles={{ marginTop: "20px" }} />}
      {!loadingPatients && (
        <Box display="flex" flexDirection="row" gap={10}>
          <Box flex={1}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6">Patients</Typography>
                <AddIcon onClick={() => setAddPatientModalOpen(true)} style={{ color: Colors.primary, cursor: "pointer", marginLeft: "5px" }} />
              </Box>
              <PrimaryButton onClick={() => setPatientPriorityModalOpen(true)} style={{ padding: "2px 10px", fontSize: "0.9rem" }}>
                Set Priority
              </PrimaryButton>
            </Box>
            <Paper
              component="form"
              sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400, backgroundColor: Colors.bg3, color: "white" }}
            >
              <TextField
                sx={{ flex: 1, color: "white" }}
                placeholder="Search"
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
              <IconButton sx={{ p: "10px" }}>
                <SearchIcon />
              </IconButton>
            </Paper>
            {filteredPatients.map((patient: OmniPatient) => (
              <PatientCard key={patient.patientId} omniPatient={patient} updatePatient={updatePatient} updateOpen={updateOpen} />
            ))}
          </Box>
          <Box flex={1}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6">Groups</Typography>
                <AddIcon onClick={() => setAddGroupModalOpen(true)} style={{ color: Colors.primary, cursor: "pointer", marginLeft: "5px" }} />
                {addLoadingGroups && <CircularProgress size={20} sx={{ marginLeft: "5px" }} />}
              </Box>
              <PrimaryButton onClick={() => setGroupPriorityModalOpen(true)} style={{ padding: "2px 10px", fontSize: "0.9rem" }}>
                Set Priority
              </PrimaryButton>
            </Box>
            {groups.map((group) => (
              <Box key={group.patientGroupId}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(256, 256, 256, 0.12)",
                    borderRadius: "15px",
                    padding: "12px",
                    margin: "20px 0",
                    position: "relative",
                  }}
                >
                  {group.name}{" "}
                  <IconButton onClick={() => onSelectGroup(group)} style={{ color: Colors.primary, position: "absolute", right: "10px" }}>
                    <EditIcon />
                  </IconButton>
                  {patients.filter((patient) => patient.waitlist.patientGroupId === group.patientGroupId).length === 0 && (
                    <IconButton onClick={() => deleteGroup(group)} style={{ color: Colors.primary, position: "absolute", right: "40px" }}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
                {patients
                  .filter((patient) => patient.waitlist.patientGroupId === group.patientGroupId)
                  .map((patient) => (
                    <PatientCard key={patient.patientId} omniPatient={patient} updatePatient={updatePatient} updateOpen={updateOpen} />
                  ))}
              </Box>
            ))}
          </Box>
        </Box>
      )}
      <Modal open={addPatientModalOpen} onClose={() => setAddPatientModalOpen(false)}>
        <Sidebar className={addPatientModalOpen ? "open" : ""}>
          {organization?.integrations.includes(EHR.healthie) ? (
            <HealthieAddPatientModal
              onClick={() => {}}
              onClose={() => {
                setAddPatientModalOpen(false);
              }}
            />
          ) : organization?.integrations.includes(EHR.webpt) ? (
            <WebptAddPatientModal
              onClick={() => {}}
              onClose={() => {
                setAddPatientModalOpen(false);
              }}
            />
          ) : null}
        </Sidebar>
      </Modal>
      <Modal open={addGroupModalOpen} onClose={onAddGroupModalClose}>
        <Sidebar className={addGroupModalOpen ? "open" : ""}>
          <AddGroupModal patients={patients} onSuccess={onAddGroupModalClose} onClose={() => setAddGroupModalOpen(false)} />
        </Sidebar>
      </Modal>
      {selectedPatient && (
        <Sidebar className={updateOpen ? "open" : ""}>
          {organization?.integrations.includes(EHR.healthie) ? (
            <UpdateHealthiePatientModal
              key={selectedPatient.patientId}
              open={updateOpen}
              onClose={() => setUpdateOpen(false)}
              patient={selectedPatient}
            />
          ) : organization?.integrations.includes(EHR.webpt) ? (
            <UpdateWebptPatientModal
              key={selectedPatient.patientId}
              open={updateOpen}
              onClose={() => setUpdateOpen(false)}
              patient={selectedPatient}
            />
          ) : null}
        </Sidebar>
      )}
      {selectedGroup && selectedGroupPatientIds && (
        <Sidebar className={updateGroupOpen ? "open" : ""}>
          <UpdateGroupModal
            key={selectedGroup.patientGroupId}
            onSuccess={onUpdateGroupModalClose}
            onClose={() => setUpdateGroupOpen(false)}
            patients={patients}
            patientGroup={selectedGroup}
            groupPatientIds={selectedGroupPatientIds}
          />
        </Sidebar>
      )}

      <PriorityModal
        key={`patients-priority-modal-key-${patients.length}`}
        title="Patient Priority"
        sortedItems={patients}
        open={patientPriorityModalOpen}
        onSuccess={onPatientPriorityClose}
        onClose={() => setPatientPriorityModalOpen(false)}
        getItemId={(patient) => patient.patientId}
        getItemName={(patient) => `${patient.patient.firstName} ${patient.patient.lastName}`}
      />
      <PriorityModal
        key={`groups-priority-modal-key-${groups.length}`}
        title="Group Priority"
        sortedItems={groups}
        open={groupPriorityModalOpen}
        onSuccess={onGroupPriorityClose}
        onClose={() => setGroupPriorityModalOpen(false)}
        getItemId={(group) => group.patientGroupId}
        getItemName={(group) => group.name}
      />
    </Box>
  );
};

export default Patients;
