import React, { useEffect, useRef, useState } from "react";
import Link from "@mui/material/Link";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  Avatar,
  CircularProgress,
  Icon,
  Tooltip,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  styled,
  Tab,
  Tabs,
} from "@mui/material";
import { useSnackbar } from "../providers/SnackbarProvider";
import { useNavigate, useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../store";
import { useSelector } from "react-redux";
import {
  Call,
  FrontendUser,
  StreamType,
  TextThreadMessage,
  TextWebsocketEvents,
  WaitlistRun,
  WaitlistSlot,
  WaitlistSlotStateChange,
  WebsocketActions,
} from "../types";
import axios from "axios";
import { IconWithHover, OvalOutlineListItem } from "../styles/GeneralStyles";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TimerIcon from "@mui/icons-material/Timer";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotesIcon from "@mui/icons-material/Notes";
import CloseIcon from "@mui/icons-material/Close";
import EventIcon from "@mui/icons-material/Event";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import LoopIcon from "@mui/icons-material/Loop";
import SendIcon from "@mui/icons-material/Send";
import { PatientStatusAvatarTooltip, statusMessages } from "./WaitlistRunInfo";
import { api, formatIsoToCustomDateStringWithEEEEHHMMA } from "../utils/utils";
import MessageIcon from "@mui/icons-material/Message";
import TextThreadDisplay from "../components/TextThreadDisplay";
import AudioScrubber from "../subcomponents/AudioScrubber";
import { Colors } from "../Colors";
import { SecondaryButton, TransparentButton } from "../subcomponents/CustomButton";
import AudioComponent from "../subcomponents/AudioComponent";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { fetchWaitlistRun, fetchWaitlistRuns } from "../slices/WaitlistRunsSlice";
import { fetchWaitlistSlot, updateWaitlistSlot } from "../slices/WaitlistSlotsSlice";
import { DateTime } from "luxon";

const WaitlistSlotInfo: React.FC = () => {
  const { agentId, waitlistRunId, waitlistSlotId } = useParams<{ agentId: string; waitlistRunId: string; waitlistSlotId: string }>();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const { showMessage } = useSnackbar();

  const runsAgentId = useSelector((state: RootState) => state.waitlistRuns.agentId);
  const waitlistRuns = useSelector((state: RootState) => state.waitlistRuns.waitlistRuns);
  const waitlistRun = waitlistRunId ? waitlistRuns[waitlistRunId] : undefined;
  const waitlistSlots = useSelector((state: RootState) => state.waitlistSlots.waitlistSlots);
  const waitlistSlot: WaitlistSlot | undefined = waitlistSlotId ? waitlistSlots[waitlistSlotId] : undefined;
  const loading = !!waitlistSlotId && useSelector((state: RootState) => state.waitlistSlots.waitlistSlotsLoading[waitlistSlotId]);

  const [textThread, setTextThread] = useState<TextThreadMessage[] | undefined>(undefined);
  const [call, setCall] = useState<any | undefined>(undefined);

  const agent = useSelector((state: RootState) => state.agents.agents.find((agent) => agent.agentId === agentId));
  const [selectedTab, setSelectedTab] = useState(waitlistSlot?.callEnabled && !waitlistSlot.textEnabled ? 1 : 0); // 0 for Text, 1 for Call

  const [textThreadLoading, setTextThreadLoading] = useState(true);
  const [waitlistSlotStateChanges, setWaitlistSlotStateChanges] = useState<WaitlistSlotStateChange[] | undefined>(undefined);
  const [callLoading, setCallLoading] = useState(true);

  const socketRef = useRef<WebSocket | null>(null);

  const userTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user?.token) {
      if (waitlistSlotId) dispatch(fetchWaitlistSlot({ token: user.token, waitlistSlotId }));
      if (waitlistRunId) dispatch(fetchWaitlistRun({ token: user.token, waitlistRunId }));
    }
  }, [user?.token, waitlistSlotId]);

  const navigate = useNavigate();

  const handleBack = () => {
    const currentPath = location.pathname;
    const pathParts = currentPath.split("/").slice(0, -2).join("/"); // removes /calls/:callId from the end of the url
    navigate(pathParts || "/dashboard");
  };

  const getTextThread = async () => {
    if (!waitlistSlot?.textEnabled || !user?.token) {
      return undefined;
    }
    try {
      const response = await api.get(`/texts/agent/${agentId}/patient/${waitlistSlot.patientId}`, user?.token);
      return response.data;
    } catch (error) {
      console.error(error);
      showMessage("Failed to get text. Please try again later.", "error");
    }
  };

  const getWaitlistSlotStateChanges = async () => {
    if (!waitlistSlot?.waitlistSlotId || !user?.token) {
      return undefined;
    }
    try {
      const response = await api.get(`/patients/${waitlistSlot.patientId}/waitlist-slot-state-changes`, user?.token);
      setWaitlistSlotStateChanges(response.data);
    } catch (error) {
      console.error(error);
      showMessage("Failed to get waitlist slot state changes. Please try again later.", "error");
    }
  };

  const loadTextThread = async () => {
    try {
      setTextThreadLoading(true);
      const [textThread, _] = await Promise.all([getTextThread(), getWaitlistSlotStateChanges()]);
      setTextThread(textThread);
    } catch (error) {
      console.error("Error loading text thread or state changes:", error);
    } finally {
      setTextThreadLoading(false);
    }
  };

  const getCall = async () => {
    if (!waitlistSlot?.callId || !user?.token) {
      return undefined;
    }
    try {
      const response = await api.get(`/calls/${waitlistSlot.callId}?getAgentName=true`, user?.token);
      return response.data.call;
    } catch (error) {
      console.error(error);
      showMessage("Failed to get call. Please try again later.", "error");
    }
  };

  const loadCall = async () => {
    setCallLoading(true);
    const call = await getCall();
    setCall(call);
    setCallLoading(false);
  };

  useEffect(() => {
    loadTextThread();
    loadCall();
  }, [waitlistSlot?.waitlistSlotId]);

  useEffect(() => {
    if (!user || !user?.userId || !user?.token) {
      return;
    }

    // Check if a WebSocket connection already exists
    if (socketRef.current && (socketRef.current.readyState === WebSocket.OPEN || socketRef.current.readyState === WebSocket.CONNECTING)) {
      console.log("WebSocket connection is already open or connecting.");
      return;
    }

    const connectWebSocket = async () => {
      try {
        if (!user?.token) {
          return;
        }
        const socket = new WebSocket(
          `wss://${process.env.REACT_APP_BACKEND_URL_NO_HTTPS}/api/text-subscriptions/${user.userId}?token=${encodeURIComponent(user.token)}`
        );
        socketRef.current = socket;

        socket.onopen = async () => {
          // Add a delay to ensure the WebSocket connection is ready before subscribing
          // TODO (Hizami) - Figure out why the websocket connection is not immediately ready
          await new Promise((resolve) => setTimeout(resolve, 1000));
          console.log("Connected to WebSocket");
          socket.send(JSON.stringify({ action: WebsocketActions.subscribe, type: TextWebsocketEvents.textThreadMessages }));
          socket.send(JSON.stringify({ action: WebsocketActions.subscribe, type: TextWebsocketEvents.waitlistSlotStateChanges }));
        };

        socket.onmessage = (event: MessageEvent<string>) => {
          const message: { eventType: TextWebsocketEvents; streamType: StreamType; data: TextThreadMessage | WaitlistSlotStateChange } = JSON.parse(
            event.data
          ) as {
            eventType: TextWebsocketEvents;
            streamType: StreamType;
            data: TextThreadMessage | WaitlistSlotStateChange;
          };

          switch (message.eventType) {
            case TextWebsocketEvents.textThreadMessages:
              switch (message.streamType) {
                case StreamType.INSERT: {
                  const textThreadMessage: TextThreadMessage = message.data as TextThreadMessage;
                  setTextThread((prevTextThread: TextThreadMessage[] | undefined) => [...(prevTextThread || []), textThreadMessage]);
                  break;
                }
              }
              break;
            case TextWebsocketEvents.waitlistSlotStateChanges:
              switch (message.streamType) {
                case StreamType.INSERT: {
                  const waitlistSlotStateChange: WaitlistSlotStateChange = message.data as WaitlistSlotStateChange;
                  console.log("Received waitlist slot state change message:", waitlistSlotStateChange);
                  dispatch(
                    updateWaitlistSlot({
                      waitlistSlotId: waitlistSlotStateChange.waitlistSlotId,
                      state: waitlistSlotStateChange.state,
                      stateJustification: waitlistSlotStateChange.stateJustification,
                    })
                  );
                  break;
                }
              }
              break;
            default:
              console.log("Received unknown message:", message);
              break;
          }
        };

        socket.onclose = () => {
          console.log("Disconnected from WebSocket");
          socketRef.current = null;
        };

        socket.onerror = (error) => {
          console.error("WebSocket error:", error);
          socket.close();
        };

        return () => {
          socket.close();
        };
      } catch (error) {
        console.error("Error connecting to WebSocket:", error);
      }
    };

    connectWebSocket();

    // Clean up the WebSocket connection when the component unmounts or user changes
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
    };
  }, [user?.token, waitlistSlotId]);

  return (
    <Box sx={{ margin: { xs: "65px 0 20px 0", lg: "0" } }}>
      <SecondaryButton onClick={handleBack} sx={{ display: { xs: "none", lg: "flex" }, alignItems: "center", margin: "20px 0" }}>
        <ArrowBackIcon sx={{ marginRight: "10px" }} /> Back to Waitlist Run
      </SecondaryButton>

      {!waitlistSlot && loading && <LoadingWithMessage message="Loading..." size={100} />}

      {waitlistSlot && (
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "space-between", width: "100%", color: "#FFF" }}>
          {/* Main content section on the left */}
          <Box sx={{ flex: 1, paddingRight: 2, maxWidth: { xs: "100%", md: "380px" } }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography gutterBottom variant="h4" component="div" sx={{ my: 2 }}>
                {`${waitlistSlot.patient?.firstName} ${waitlistSlot.patient?.lastName}`}
              </Typography>
            </Box>
            <OvalOutlineListItem>
              <ListItemIcon>
                <Avatar src={agent?.avatarUrl} alt={agent?.name} sx={{ width: 30, height: 30 }} />
              </ListItemIcon>
              {agent && <ListItemText primary="Contacted by" secondary={agent.name} secondaryTypographyProps={{ color: Colors.info }} />}
            </OvalOutlineListItem>

            <OvalOutlineListItem>
              <ListItemIcon>
                <FingerprintIcon style={{ color: "#FFF" }} />
              </ListItemIcon>
              <ListItemText primary="Patient ID" secondary={waitlistSlot.patient?.patientId} secondaryTypographyProps={{ color: Colors.info }} />
            </OvalOutlineListItem>

            {waitlistRun && userTimeZone && (
              <OvalOutlineListItem>
                <ListItemIcon>
                  <EventIcon style={{ color: "#FFF" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Appointment date"
                  secondary={waitlistRun.appointmentDate ? formatIsoToCustomDateStringWithEEEEHHMMA(DateTime.fromISO(waitlistRun.appointmentDate, { zone: "UTC" }).setZone(userTimeZone).toISO()!) : "No appointment date"}
                  secondaryTypographyProps={{ color: Colors.info }}
                />
              </OvalOutlineListItem>
            )}

            <OvalOutlineListItem alignItems="center">
              <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                <PatientStatusAvatarTooltip patientStatus={waitlistSlot.state} sx={{ mr: 2 }} /> {/* Adjust the mr value as needed */}
                <ListItemText
                  primary={`Status: ${statusMessages[waitlistSlot.state as keyof typeof statusMessages]}`}
                  secondary={waitlistSlot.stateJustification}
                  secondaryTypographyProps={{ color: Colors.info }}
                />
              </Box>
            </OvalOutlineListItem>
            <br />
          </Box>

          {/* Patients section on the right */}
          <Box
            sx={{
              flex: 1,
              paddingLeft: 2,
              maxWidth: { xs: "100%", md: "45%" },
              alignSelf: "flex-start",
            }}
          >
            <Typography gutterBottom variant="h5">
              Channels
            </Typography>

            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="text and call history tabs"
              textColor="primary"
              indicatorColor="primary"
              sx={{ marginBottom: 2 }}
            >
              {waitlistSlot?.textEnabled && <Tab label="Text Details" sx={{ textTransform: "none", color: "#FFF", fontSize: "1.1rem" }} />}
              {waitlistSlot?.callId && <Tab label="Call Details" sx={{ textTransform: "none", color: "#FFF", fontSize: "1.1rem" }} />}
            </Tabs>

            <Box>
              {!agentId ? (
                <CircularProgress />
              ) : selectedTab === 0 && waitlistSlot.textEnabled ? (
                textThreadLoading ? (
                  <LoadingWithMessage message="Loading text history..." size={30} />
                ) : (
                  textThread && (
                    <TextThreadDisplay
                      thread={textThread}
                      agentId={agentId}
                      patientId={waitlistSlot?.patientId}
                      waitlistSlotIds={[{ waitlistSlotId: waitlistSlot.waitlistSlotId, appointmentDate: waitlistRun?.appointmentDate || "" }]}
                      waitlistSlotStateChanges={waitlistSlotStateChanges || []}
                    />
                  )
                )
              ) : selectedTab === 1 && waitlistSlot?.callId ? (
                callLoading ? (
                  <LoadingWithMessage message="Loading call history..." size={30} />
                ) : (
                  call && <CallMetadataDisplay frontendCall={call} />
                )
              ) : (
                <Typography variant="h6">No history available</Typography>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

interface CallMetadataDisplayProps {
  frontendCall: Call;
}

const CallMetadataDisplay: React.FC<CallMetadataDisplayProps> = ({ frontendCall }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const [transcriptLines, setTranscriptLines] = useState<any>([]);
  const { agentId, waitlistRunId, waitlistSlotId } = useParams<{ agentId: string; waitlistRunId: string; waitlistSlotId: string }>();

  const [transcriptLoading, setTranscriptLoading] = useState(true);
  const [transcript, setTranscript] = useState<string | undefined>(undefined);

  const storeWaitlistSlots = useSelector((state: RootState) => state.waitlistSlots.waitlistSlots);
  const waitlistSlot = waitlistSlotId ? storeWaitlistSlots[waitlistSlotId] : undefined;

  useEffect(() => {
    if (transcript) {
      const lines = transcript.split("\n").map((line: string, index: number) => {
        const isAI = line.trim().startsWith("assistant:");
        const isUser = line.trim().startsWith("user:");
        const isAction = line.trim().startsWith("agent-action:");

        const cleanedLine = line.replace(/(assistant:|user:)/, "").trim();

        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: isAI ? "flex-end" : isUser ? "flex-start" : "center",
            }}
          >
            <Typography
              component="div"
              variant="body1"
              sx={{
                backgroundColor: isAI ? "#007AFF" : isUser ? Colors.bgTextMsg : "transparent",
                color: isAI || isUser ? "#FFF" : Colors.warning,
                padding: "10px 15px",
                borderRadius: "15px",
                fontSize: "0.9rem",
                fontWeight: isAction ? "bold" : "normal",
                maxWidth: "75%",
                marginBottom: "15px",
              }}
            >
              <strong style={{ color: isUser ? Colors.primary : "#EEE" }}>{isAI ? "Agent: " : isUser ? "Patient: " : ""}</strong>
              <br /> {cleanedLine}
            </Typography>
          </Box>
        );
      });
      setTranscriptLines(lines);
    } else {
      setTranscriptLines([]);
    }
  }, [transcript]);

  useEffect(() => {
    getTranscript();
  }, []);

  const getTranscript = async () => {
    try {
      setTranscriptLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/calls/${waitlistSlot?.callId}/transcript`, {
        headers: { Authorization: `Bearer ${user?.token}` },
      });
      setTranscript(response.data.transcript?.transcript);
    } catch (error) {
      console.error(error);
    } finally {
      setTranscriptLoading(false);
    }
  };

  return (
    <>
      <Box margin={0}>
        <Typography variant={"h6"} sx={{ marginBottom: 1 }}>
          Call recording
        </Typography>
        <AudioComponent audioURL={`${process.env.REACT_APP_BACKEND_URL}/api/calls/${frontendCall.callId}/streamMp3File`} />

        {/* <SecondaryButton onClick={toggleTranscript} style={{ marginBottom: "10px", fontFamily: "sans-serif", fontWeight: "bold" }}>
          {showTranscript ? "Hide Call Transcript" : "View Call Transcript"}
        </SecondaryButton> */}

        <Typography variant="h6" sx={{ marginBottom: 1, marginTop: 2 }}>
          Call transcript
        </Typography>

        {/* {showTranscript && ( */}
        {transcriptLoading && <LoadingWithMessage message="Loading transcript..." size={30} />}
        <CallTranscriptContainer>
          <>{transcriptLines}</>
        </CallTranscriptContainer>
        {/* )} */}
      </Box>
    </>
  );
};

export const callOutcomeIcons = {
  accepted: <EventAvailableIcon />,
  declined: <EventBusyIcon />,
  requested_callback: <Box width={24} height={24} />,
  unknown: <Box width={24} height={24} />,
  voicemail: <Box width={24} height={24} />,
};

export const callOutcomeMessages = {
  accepted: "Accepted",
  declined: "Declined",
  requested_callback: "Requested Callback",
  unknown: "Unsuccessful",
  voicemail: "Voicemail",
};

export const CallOutcomeAvatarTooltip: React.FC<{ callOutcome: any; sx?: object }> = ({ callOutcome, sx }) => {
  const message = callOutcomeMessages[callOutcome as keyof typeof callOutcomeMessages];

  return (
    <Tooltip title={message}>
      <Box component="span" sx={sx}>
        <Avatar sx={{ backgroundColor: callOutcome === "accepted" ? "#4CAF50" : "#757575" }}>
          {callOutcomeIcons[callOutcome as keyof typeof callOutcomeIcons]}
        </Avatar>
      </Box>
    </Tooltip>
  );
};

const CallTranscriptContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 45vh;
  padding: 16px;
  background-color: ${Colors.bg2};
  border-radius: 15px;
`;

export const StyledHeading = styled(Typography)`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 16px;
`;

export default WaitlistSlotInfo;
